import axios from "axios";
import awsConfig from "../config/awsConfig";
import {getAuthorizationHeader} from "../utils/authUtils";

const baseUrl = awsConfig.apiGateway.URL;

export class adminApi {

  static getOrders = async () => {
    const url = baseUrl + "/partner";
    return getAuthorizationHeader().then(header => {
      return axios.get(url, header);
    });
  };
}

export default adminApi;
