import React, {Component} from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Hidden from "@material-ui/core/Hidden";
import Menu from "@material-ui/icons/Menu";
import HeaderLinks from "./HeaderLinks";
import compose from "recompose/compose";
import {connect} from "react-redux";
import headerStyle from "../../assets/jss/material-dashboard-react/components/headerStyle.jsx";
import Typography from "@material-ui/core/Typography/Typography";
import CustomBadge from "../CustomBadge";
import CustomIcon from "../CustomIcon/CustomIcon";
import {drawerWidth} from "../../assets/jss/material-dashboard-react";
import Logo from "../../components/Logo";
import {isProjectFinished} from "../../utils/utilFunctions";

class Header extends Component {

  constructor(props) {
    super(props);
    this.state = {
      mobileOpen: this.props.mobileOpen
    };
  }

  componentWillReceiveProps = (nextProps) => {
    this.setState({mobileOpen: nextProps.mobileOpen});
  };

  render() {
    const { classes, buttons } = this.props;
    
    return (
      <AppBar className={classes.appBar} 
        style={!this.state.mobileOpen
                ? this.props.drawerWidth < drawerWidth
                  ? {width: `calc(100% - ${this.props.drawerWidth}px)`} 
                  : null
                : {width: "100%"}
              }
      >
        <Toolbar className={classes.container}>
          <Hidden smDown implementation="css">
            <HeaderLinks />
          </Hidden>

          <Hidden mdUp implementation="css">
            <IconButton
              style={{color: "white", zIndex: 1}}
              aria-label="open drawer"
              onClick={this.props.handleDrawerToggle}
            >
              <Menu />
            </IconButton>
          </Hidden>

          {this.props.pageTitle
            ?
            <Typography className={classes.title}>
              {this.props.pageTitle}
            </Typography>
            :
            <div>
              <Hidden only={'xs'} implementation={"css"}>
                <Logo id={"logo"} style={{maxWidth: "237px", top: "0%", position: "absolute", left: "50%"}}/>
              </Hidden>
              <Hidden only={['sm', 'md', 'lg', 'xl']} implementation={"css"}>
                <Logo id={"logo"} style={{maxWidth: "237px", minWidth: "175px", top: "calc(0.2rem + 2px)", position: "absolute", left: "50%"}}/>
              </Hidden>
            </div>
          }
          <div>
            {
              this.props.floatingButton
              ? <this.props.floatingButton/>
              : null
            }
            {buttons.map((button, index) => {
              return (
                !isProjectFinished(this.props.project)
                ? this.props.project.totalCartItems > 0
                  ? <CustomBadge
                      className={
                        this.state.mobileOpen
                          ? classes.cartButton + " " + classes.minCartButton
                          : classes.cartButton
                      }
                      badgeContent={this.props.project.totalCartItems}
                      key={index}
                      badge={classes.badge}
                      color="primary"
                      id={"header_open_cart"}
                      onClick={button.action}
                  >
                      <CustomIcon
                        className={
                          this.state.mobileOpen
                            ? classes.cartIcon + " " + classes.minCartIcon
                            : classes.cartIcon
                        }
                        key={index}
                        icon={button.icon}
                        tooltip={"Items in cart"}
                        id={"header_open_cart_icon"}
                      />
                    </CustomBadge>
                  : <div
                      key={index}
                      onClick={button.action}
                      className={
                        this.state.mobileOpen
                          ? classes.cartButton + " " + classes.minCartButton
                          : classes.cartButton}
                    >
                      <CustomIcon
                        className={
                          this.state.mobileOpen
                            ? classes.cartIcon + " " + classes.minCartIcon
                            : classes.cartIcon
                        }
                        key={index}
                        icon={button.icon}
                        tooltip={"Items in cart"}
                        id={"header_open_cart_icon"}
                      />
                    </div>
                : null
              );
            })}
          </div>

        </Toolbar>
      </AppBar>
    );
  }
}

Header.propTypes = {
  classes: PropTypes.object.isRequired
};

function mapStateToProps(store, ownProps) {
  return {
    drawerWidth: store.app.drawerWidth,
    buttons: store.headerButtons.buttons,
    floatingButton: store.headerButtons.floatingButton,
    pageTitle: store.headerButtons.pageTitle
  };
}

export default compose(
  withStyles(headerStyle),
  connect(mapStateToProps))(Header)
;
