import {primaryColor, dangerColor} from "../../material-dashboard-react";

const style = {
  title: {
    fontSize: "34px",
    paddingTop: "10px",
    opacity: "87%"
  },
  subTitle: {
    fontSize: "20px"
  },
  signUp: {
    fontSize: "20px",
    margin: "0px",
    paddingTop: "10px"
  },
  card: {
    maxWidth: "475px"
  },
  errorTextMessage: {
    height: "35px",
    textAlign: "center",
    fontSize: "20px",
    color: dangerColor
  },
  cardActions: {
    paddingTop: "40px",
    paddingBottom: "30px",
    justifyContent: "center"
  },
  confirmationButton: {
    padding: "0px",
    fontSize: "17px",
    fontWeight: "300",
    textTransform: "none",
    height: "36px",
    width: "120px",
    borderRadius: "5px"
  },
  parentContainer: {
    display: "table-cell",
    verticalAlign: "middle",
    position: "relative",
    width: "auto"
  },
  grandParentContainer: {
    display: "table",
    height: "100vh",
    margin: "0 auto"
  },
  background: {
    position: "relative",
    backgroundSize: "cover",
    backgroundAttachment: "fixed",
    backgroundPosition: "center"
  },
  alternativeText: {
      textAlign: "center",
      color: "rgba(0, 0, 0, 0.54)",
      fontWeight: 400,
      fontFamily: ["Roboto", "Helvetica", "Arial"]
  },
  otherButton: {
    display: "block",
    margin: "15px auto",
    marginTop: 15,
    fontWeight: 800,
    fontFamily: ["Roboto", "Helvetica", "Arial"],
    textTransform: "uppercase",
    color: primaryColor,
  },
};

export default style;
