import * as React from "react";
import Dialog from "@material-ui/core/Dialog/Dialog";
import CustomTextField from "../../components/CustomTextField/CustomTextField";
import {
  isFormValid
} from "../../components/CustomTextField/validationFunctions";
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import CustomBlueButton from "../../components/CustomButtons/CustomBlueButton";
import {deepClone} from "../../utils/utilFunctions";
import {changePassword} from "../../utils/authUtils";
import Typography from "@material-ui/core/Typography/Typography";
import {primaryColor, primaryGrayColor} from "../../assets/jss/material-dashboard-react";
import withStyles from "@material-ui/core/styles/withStyles";
import Snackbar from "../../components/Snackbar/Snackbar";

const styles = {
  title: {
    backgroundColor: primaryColor,
    "& > h2": {
      color: primaryGrayColor
    }
  }
};

class ChangePasswordDialog extends React.Component {

  initialFieldValidationErrorStates = {
    oldPassword: {label: "", state: false},
    newPassword: {label: "", state: false},
    newPasswordConfirmation: {label: "", state: false}
  };

  state = {
    oldPassword: "",
    newPassword: "",
    newPasswordConfirmation: "",
    fieldValidationErrorStates: deepClone(this.initialFieldValidationErrorStates),
    saveButtonActive: false,
    incognitoErrorMessage: "",
    showPasswordChangedNotification: false
  };

  handleChange = async (event) => {
    await this.setState({[event.target.name]: event.target.value});
    if(this.state.oldPassword && this.state.newPassword && this.state.newPasswordConfirmation) {
      this.setState({saveButtonActive: isFormValid(this.state.fieldValidationErrorStates)});
    } else {
      this.setState({saveButtonActive: false});
    }
  };

  handleChangePassword = () => {
    if(this.checkPasswordMatching()) {
      changePassword(this.state.oldPassword, this.state.newPassword)
        .then(data => {
          try {
            this.showPasswordChangedNotification();
          } catch {}
          this.handleClose(data);
        })
        .catch(err => {
          this.setState({incognitoErrorMessage: err.message});
        });
    }
  };

  showPasswordChangedNotification = () => {
    this.setState({showPasswordChangedNotification: true});
    this.notificationTimeout = setTimeout(
      () => {
        this.hidePasswordChangedNotification();
      },
      1500
    );
  };

  hidePasswordChangedNotification = () => {
    this.setState({showPasswordChangedNotification: false});
  };

  checkPasswordMatching = () => {
    if(this.state.newPassword !== this.state.newPasswordConfirmation) {
      let fieldValidationErrorStates = this.state.fieldValidationErrorStates;
      fieldValidationErrorStates.newPasswordConfirmation.label = " - passwords do not match";
      fieldValidationErrorStates.newPasswordConfirmation.state = true;
      this.setState({saveButtonActive: false});
      return false;
    } else {
      return true;
    }
  };

  handleClose = () => {
    this.props.handleCloseModal();
    this.setState({
      oldPassword: "",
      newPassword: "",
      newPasswordConfirmation: "",
      fieldValidationErrorStates: deepClone(this.initialFieldValidationErrorStates),
      saveButtonActive: false,
      incognitoErrorMessage: ""
    })
  };

  componentWillUnmount = () => {
    clearTimeout(this.notificationTimeout);
  };


  render() {
    const {oldPassword, newPassword, newPasswordConfirmation, fieldValidationErrorStates} = this.state;

    return (
      <div>
        <Dialog
          open={this.props.open}
          onClose={this.props.handleCloseModal}
        >
          <DialogTitle className={this.props.classes.title}>
            Change Password
          </DialogTitle>
          <DialogContent>
            <Typography color={"error"}>{this.state.incognitoErrorMessage}</Typography>
            <CustomTextField
              fullWidth
              id={"employee_old_password_field"}
              label={"Old Password"}
              margin="dense"
              value={oldPassword}
              handleChange={this.handleChange}
              name={"oldPassword"}
              type={"password"}
              required
              error={fieldValidationErrorStates["oldPassword"]}
            />
            <CustomTextField
              fullWidth
              id={"employee_new_password_field"}
              label={"New Password"}
              margin="dense"
              value={newPassword}
              handleChange={this.handleChange}
              name={"newPassword"}
              type={"password"}
              required
              error={fieldValidationErrorStates["newPassword"]}
            />
            <CustomTextField
              fullWidth
              id={"employee_new_password_confirmation_field"}
              label={"Confirm New Password"}
              margin="dense"
              value={newPasswordConfirmation}
              handleChange={this.handleChange}
              name={"newPasswordConfirmation"}
              type={"password"}
              required
              error={fieldValidationErrorStates["newPasswordConfirmation"]}
            />
          </DialogContent>
          <DialogActions>
            <CustomBlueButton
              label={"Save"}
              variant={"contained"}
              color={"primary"}
              onClick={this.handleChangePassword}
              handleEnterKeypress
              id={"employee_confirm_change_password"}
              disabled={!this.state.saveButtonActive}
            />
            <CustomBlueButton
              color={"primary"}
              onClick={this.handleClose}
              label={"Cancel"}
              id={"employee_cancel_change_password"}
            />
          </DialogActions>
        </Dialog>
        <Snackbar
          color = "primary"
          place= "tr"
          open={this.state.showPasswordChangedNotification}
          closeNotification={this.hidePasswordChangedNotification}
          message={"Password changed successfully!"}
          close
          />
      </div>
    )
  }
}

export default withStyles(styles)(ChangePasswordDialog);
