import * as types from "../actions/actionTypes";

let initialState = {
  buttons: [],
  pageTitle: "",
  floatingButton: null
};

export default function headerButtonsReducer(state = initialState, action) {
  switch (action.type) {
    case types.CHANGE_HEADER_BUTTONS:
      return {...state, buttons: action.buttons};
    case types.CHANGE_PAGE_TITLE:
      return {...state, pageTitle: action.pageTitle};
    case types.SET_FLOATING_BUTTON:
      return {...state, floatingButton: action.floatingButton};
    default:
      return state;
  }
}
