import React, {Component} from "react";
import Card from "@material-ui/core/Card";
import {connect} from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import compose from "recompose/compose";
import style from "../../assets/jss/material-dashboard-react/components/registration";
import {bindActionCreators} from "redux";
import {HashRouter} from "react-router-dom";
import history from "../../routes/history";
import * as registrationActions from "../../actions/registrationActions";
import RegistrationRoutes from "../../routes/RegistrationRoutes";

class Registration extends Component {

  render() {
    const {classes} = this.props;
    return (
      <div className={classes.background}>
        <div className={classes.grandParentContainer}>
          <div className={classes.parentContainer}>
            <Card className={classes.card}>
              <HashRouter history={history}>
                <RegistrationRoutes/>
              </HashRouter>
            </Card>
          </div>
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign({}, registrationActions), dispatch)
  }
}

export default compose(
  withStyles(style),
  connect(null, mapDispatchToProps)
)(Registration);
