import * as types from "./actionTypes";
import {handleFailure} from "./errorHandler";
import adminApi from "../api/adminApi";

export function getOrders() {
  return function(dispatch) {
    return adminApi.getOrders().then(response => {
      const orders = response.data;
      dispatch({type: types.ORDERS_GET_SUCCESS, orders});
    }).catch(async (error) => {
      await handleFailure(error, dispatch, "getAllUsers");
    })
  }
}
