import React from "react";
import PropTypes from "prop-types";
import Dialog from "@material-ui/core/Dialog/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import CustomBlueButton from "./CustomButtons/CustomBlueButton";
import {primaryColor, primaryGrayColor} from "../assets/jss/material-dashboard-react";
import withStyles from "@material-ui/core/styles/withStyles";

const style = {
  title: {
    backgroundColor: primaryColor,
    marginBottom: "15px",
    "& > h2": {
      color: primaryGrayColor
    }
  }
};

class ConfirmationDialog extends React.Component {

  state = {
    yesDisabled: false
  };

  actionNo = async () => {
    if(!!this.props.actionNo) {
      this.props.actionNo();
    }
  };

  componentWillReceiveProps = (nextProps) => {
      this.setState({yesDisabled: nextProps.yesDisabled});
    
  };

  actionYes = async () => {
    if(!!this.props.actionYes) {
      try {
        await this.setState({yesDisabled: true});
        await this.props.actionYes();
      } catch {
        this.setState({yesDisabled: false});
      }
    }
  };

  actionThird = async () => {
    if(!!this.props.actionThird) {
      await this.props.actionThird();
    }
  };

  render() {
    const {title, content, type, textYes, textNo, open, handleCloseModal, actionYes, actionNo, actionThird,
      yesDisabled, classes, textThirdAction, ...rest} = this.props;
    return (
      <Dialog
        open={open}
        onClose={handleCloseModal}
        aria-labelledby="form-dialog-title"
        {...rest}
      >
        <DialogTitle className={classes.title} id={"confirmation_dialog_title"}>
          {title}
        </DialogTitle>
        <DialogContent id={"confirmation_dialog_content"}>
          {content}
        </DialogContent>
        <DialogActions>
          <CustomBlueButton
            onClick={this.actionYes}
            variant="contained"
            color="primary"
            label={textYes}
            disabled={yesDisabled || this.state.yesDisabled}
            handleEnterKeypress
            id={"affirmation_button"}
          />
          {
            type === "yesNoCancel"
            ? <CustomBlueButton
                onClick={this.actionThird}
                variant="contained"
                color="primary"
                label={textThirdAction}
                id={"third_action_button"}
              />
            : null
          }
          {
            type === "prompt" || type === "yesNoCancel"
              ?
                <CustomBlueButton 
                  onClick={this.actionNo}
                  label={textNo}
                  id={"negation_button"}
                />
              : null
          }

        </DialogActions>
      </Dialog>
    )
  }
}

ConfirmationDialog.defaultProps = {
  type: "prompt",
  textYes: "Yes",
  textNo: "No",
  maxWidth: "md",
  title: ""
};

ConfirmationDialog.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  type: PropTypes.oneOf(["info", "error", "prompt", "yesNoCancel"]),
  actionYes: PropTypes.func,
  actionNo: PropTypes.func,
  actionThird: PropTypes.func,
  textYes: PropTypes.string,
  textNo: PropTypes.string,
  yesDisabled: PropTypes.bool,
  handleCloseModal: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired
};

export default withStyles(style)(ConfirmationDialog);
