import * as types from "./actionTypes";

export function setLoading(loading) {
  return {type: types.APP_SET_LOADING, loading};
}

export function setMessage(message) {
  return {type: types.APP_SET_MESSAGE, message};
}

export function switchDrawerWidth(drawerWidth) {
  return {type: types.APP_SWITCH_DRAWER_WIDTH, drawerWidth}
}

