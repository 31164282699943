import Dashboard from "../layouts/Dashboard/Dashboard.jsx";
import Registration from "../layouts/Registration/Registration";
import {loginPath} from "../constants/paths";

const indexRoutes = [
  { path: loginPath, component: Registration },
  { path: "/", component: Dashboard }
];

export default indexRoutes;
