import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import headerLinksStyle from "../../assets/jss/material-dashboard-react/components/headerLinksStyle";

class HeaderLinks extends React.Component {

  render() {
    const { classes } = this.props;
    return (
      <div>
        <div className={classes.searchWrapper}>
        </div>
      </div>
    );
  }
}

export default withStyles(headerLinksStyle)(HeaderLinks);
