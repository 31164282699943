import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import {NavLink} from "react-router-dom";
import withStyles from "@material-ui/core/styles/withStyles";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Icon from "@material-ui/core/Icon";
import HeaderLinks from "../../components/Header/HeaderLinks.jsx";
import sidebarStyle from "../../assets/jss/material-dashboard-react/components/sidebarStyle.jsx";
import * as utilFunctions from "../../utils/utilFunctions";
import compose from "recompose/compose";
import signOut from "../../utils/authUtils";
import UserAvatar from "react-user-avatar";
import NavigateBefore from "@material-ui/icons/NavigateBefore";
import NavigateNext from "@material-ui/icons/NavigateNext";
import {bindActionCreators} from "redux";
import * as appActions from "../../actions/appActions";
import {connect} from "react-redux";
import {
  drawerWidth,
  drawerWidthMin,
  mobileOpenWidth
} from "../../assets/jss/material-dashboard-react";
import Tooltip from '@material-ui/core/Tooltip';
import Button from "@material-ui/core/Button/Button";
import Menu from '@material-ui/core/Menu';
import * as registrationActions from "../../actions/registrationActions";
import {convertToSnakeCase, objIsEmpty} from "../../utils/utilFunctions";
import SidebarSubmenu from "./SidebarSubmenu.jsx";
import MenuItem from "@material-ui/core/MenuItem";
import ChangePasswordDialog from "../../views/Registration/ChangePasswordDialog";

class Sidebar extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      openDrawer: true,
      anchorEl: null,
      editProfileDialogOpen: false,
      changePasswordDialogOpen: false,
      isUserAdmin: false
    };
  }

  componentDidMount = () => {
    window.addEventListener("resize", this.resetDrawer);
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.currentUser.rolePermissions.role && (nextProps.currentUser.rolePermissions.role.id !== 2)) {
      this.setState({
        isUserAdmin: true
      });
    }
  }

  handleDrawer = () => {
    if (this.state.openDrawer) {
      this.props.actions.switchDrawerWidth(drawerWidthMin);
    } else {
      this.props.actions.switchDrawerWidth(drawerWidth);
    }
    this.setState({openDrawer: !this.state.openDrawer});
  };

  resetDrawer = () => {
    if (!this.state.openDrawer && window.innerWidth < mobileOpenWidth) {
      this.props.actions.switchDrawerWidth(drawerWidth);
      this.setState({openDrawer: true});
    }
  };

  handleClick = event => {
    this.setState({anchorEl: event.currentTarget});
  };

  handleCloseMenu = () => {
    this.setState({anchorEl: null});
  };

  handleOpenChangePasswordDialog = () => {
    this.setState({changePasswordDialogOpen: true});
    this.handleCloseMenu();
  };

  handleCloseChangePasswordDialog = () => {
    this.setState({changePasswordDialogOpen: false});
  };

  render() {

    const {props} = this;

    const logout = async () => {
      await signOut(this.props.history, true);
    };

    // verifies if routeName is the one active (in browser input)
    function activeRoute(routeName) {
      if (routeName) {
        let firstElementOfPath = utilFunctions.getPathWithoutHash();
        return routeName.includes(firstElementOfPath);
      }
    }

    const {classes, color, userPicture, backgroundImage, welcomeText, routes, currentUser, permissionLevel} = props;
    const {anchorEl} = this.state;
    let links = (

      <List className={classes.list}>
        {routes.map((prop, key) => {
          if (prop.redirect) return null;
          if (prop.hidden) return null;
          let activePro = " ";
          let listItemClasses;

          listItemClasses = classNames({
            [" " + classes[color]]: activeRoute(prop.path)
          });

          const fontColorClasses = classNames({
            [" " + classes.fontColor]: activeRoute(prop.path)
          });
          return (
            <div key={key}>
              {prop.childrenRoutes
                ? <SidebarSubmenu
                  fontColorClasses={fontColorClasses}
                  id={"side_menu_" + convertToSnakeCase(prop.sidebarName) + "_navigation"}
                  color={color}
                  openDrawer={this.state.openDrawer}
                  activeRoute={activeRoute}
                  listItemClasses={listItemClasses}
                  prop={prop}
                />
                : !prop.permissionIndex || (prop.permissionIndex && permissionLevel && permissionLevel[prop.permissionIndex] === "1")
                  ? <NavLink
                    to={prop.path}
                    className={activePro + classes.item}
                    activeClassName="active"
                    key={key}
                    id={"side_menu_" + convertToSnakeCase(prop.sidebarName) + "_navigation"}
                    onClick={() => {
                      if (this.props.mobileOpen) {
                        this.props.handleDrawerToggle();
                      }
                    }}
                  >
                    <ListItem button className={classes.itemLink + listItemClasses}>
                      <ListItemIcon
                        className={(this.state.openDrawer ? classes.itemIcon : classes.itemIconClosed) + fontColorClasses}>
                        {
                          typeof prop.icon === "string"
                            ? <Icon style={{fontSize: "28px"}}>{prop.icon}</Icon>
                            : <prop.icon style={{fontSize: "28px"}}></prop.icon>
                        }
                      </ListItemIcon>
                      {
                        this.state.openDrawer
                          ? <ListItemText
                            primary={prop.sidebarName}
                            className={classes.itemText + fontColorClasses}
                            disableTypography={true}
                          />
                          : null
                      }
                    </ListItem>
                  </NavLink>
                  : null
              }
            </div>
          );
        })}
      </List>
    );
    let userInfo = (
      <div>
        <div className={classes.logo}>
          <Button
            className={classes.buttonStyle}
            aria-owns={anchorEl ? 'simple-menu' : undefined}
            aria-haspopup="true"
            onClick={this.handleClick}
            id={"side_menu_profile_button"}
          >
            <UserAvatar
              className={classes.logoImage}
              size="35"
              colors={["#ff8f00"]}
              name={currentUser.firstName + " " + currentUser.lastName}
              src={objIsEmpty(userPicture) ? "" : userPicture}
            />
          </Button>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={this.handleCloseMenu}
            className={classes.menuItems}
          >
            <MenuItem onClick={this.handleOpenChangePasswordDialog} id={"side_menu_change_password"}>
              <Icon style={{marginRight: "10px"}}>{"https"}</Icon>Change Password
            </MenuItem>
            <MenuItem onClick={logout} id={"side_menu_logout"}>
              <Icon style={{marginRight: "10px"}}>{"power_settings_new"}</Icon><span
              className={classes.textMenuItems}>Logout</span>
            </MenuItem>
          </Menu>
          {this.state.openDrawer
            ? <div style={{
              width: "175px",
              height: "50px",
              overflow: "hidden",
              fontWeight: 400
            }}>
              {welcomeText}
            </div>
            : <div/>
          }
        </div>
      </div>
    );
    return (
      <div>
        <Hidden mdUp implementation="css"> {/*this is for portrait mode*/}
          <Drawer
            variant="temporary"
            anchor="left"
            open={props.mobileOpen}
            classes={{
              paper: classes.drawerPaper
            }}
            onClose={props.handleDrawerToggle}
            ModalProps={{
              keepMounted: true // Better open performance on mobile.
            }}
          >
            {userInfo}
            <div className={classes.sidebarWrapper}>
              <HeaderLinks/>
              {links}
            </div>
            {backgroundImage !== undefined ? (
              <div
                className={classes.background}
                style={{backgroundImage: "url(" + backgroundImage + ")"}}
              />
            ) : null}
          </Drawer>
        </Hidden>
        <Hidden smDown implementation="css"> {/*this is for landscape mode*/}
          <Drawer
            anchor="left"
            variant="permanent"
            open={this.state.open}
            classes={{
              paper: classNames({
                [classes.drawerPaperClosed]: !this.state.openDrawer,
                [classes.drawerPaper]: this.state.openDrawer,
              })
            }}
          >
            {userInfo}
            <div className={classes.sidebarWrapper}>
              {links}
              {this.state.openDrawer === true ? (
                <div className={classes.dividerFooter}>
                  <Tooltip title="Minimize" placement="top" enterDelay={500} leaveDelay={200}>
                    <NavigateBefore onClick={this.handleDrawer} className={classes.minimizeIcon}
                                    id={"side_menu_minimize"}/>
                  </Tooltip>
                </div>
              ) : (
                <div>
                  <div className={classes.dividerFooter}/>
                  <Tooltip title="Maximize" placement="top" enterDelay={500} leaveDelay={200}>
                    <NavigateNext onClick={this.handleDrawer} className={classes.maximizeIcon}
                                  id={"side_menu_maximize"}/>
                  </Tooltip>
                </div>
              )}
            </div>
            {backgroundImage !== undefined ? (
              <div
                className={classes.background}
                style={{backgroundImage: "url(" + backgroundImage + ")"}}
              />
            ) : null}
          </Drawer>
        </Hidden>

        <ChangePasswordDialog
          open={this.state.changePasswordDialogOpen}
          handleCloseModal={this.handleCloseChangePasswordDialog}
        />
      </div>
    )
  }
}

Sidebar.propTypes = {
  classes: PropTypes.object.isRequired
};

function mapStateToProps(store, ownProps) {
  return {
    drawerWidth: store.drawerWidth,
    permissionLevel: store.user.permissionLevel
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign({}, appActions, registrationActions), dispatch)
  }
}

export default compose(
  withStyles(sidebarStyle),
  connect(mapStateToProps, mapDispatchToProps)
)(Sidebar);
