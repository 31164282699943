import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import {NavLink} from "react-router-dom";
import withStyles from "@material-ui/core/styles/withStyles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Icon from "@material-ui/core/Icon";
import sidebarStyle from "../../assets/jss/material-dashboard-react/components/sidebarStyle.jsx";
import {convertToSnakeCase} from "../../utils/utilFunctions";
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import compose from "recompose/compose";
import {connect} from "react-redux";

class SidebarSubMenu extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      submenuOpen: true
    };
  }

  subMenuToggle = () => {
    this.setState(
      state => ({
        submenuOpen: !state.submenuOpen
      })
    );
  }

  render() {

    const {props} = this;
    const {classes, listItemClasses, prop, fontColorClasses, color, activeRoute, openDrawer, id, permissionLevel} = props;
    return (
      <div>
        <ListItem button className={classes.itemLink + listItemClasses} onClick={this.subMenuToggle}
                  id={id}>
          <ListItemIcon
            className={(openDrawer ? classes.itemIcon : classes.itemIconClosed) + fontColorClasses}>
            {
              typeof prop.icon === "string"
                ? <Icon style={{fontSize: "28px"}}>{prop.icon}</Icon>
                : <prop.icon style={{fontSize: "28px"}}></prop.icon>
            }
          </ListItemIcon>
          {
            openDrawer
              ? <ListItemText
                primary={prop.sidebarName}
                className={classes.itemText + fontColorClasses}
                disableTypography={true}
              />
              : null
          }
          {this.state.submenuOpen
            ? <ExpandLess style={{position: "absolute", right: "0px", top: "13px"}}
                          id={"expand_less_icon"}/>
            : <ExpandMore style={{position: "absolute", right: "0px", top: "13px"}}
                          id={"expand_more_icon"}/>}
        </ListItem>
        <Collapse in={this.state.submenuOpen}>
          <List>
            {
              prop.childrenRoutes.map((val, key) => {
                  if (prop.redirect) return null;
                  let activePro = " ";
                  let listItemClasses;

                  listItemClasses = classNames({
                    [" " + classes[color]]: activeRoute(val.path)
                  });

                  const fontColorClasses = classNames({
                    [" " + classes.fontColor]: activeRoute(val.path)
                  });
                  return (
                    permissionLevel && permissionLevel[val.permissionIndex] === "1"
                      ? <NavLink
                        to={val.path}
                        className={activePro + classes.item}
                        activeClassName="active"
                        key={"sub_menu_" + key}
                        id={"sub_menu_" + convertToSnakeCase(val.sidebarName) + "_navigation"}>
                        <ListItem button className={classes.itemLink + listItemClasses}>
                          <ListItemIcon
                            className={(openDrawer ? classes.itemIcon : classes.itemIconClosed) + fontColorClasses}>
                            {
                              typeof val.icon === "string"
                                ? <Icon style={{fontSize: "28px"}}>{val.icon}</Icon>
                                : <val.icon style={{fontSize: "28px"}}></val.icon>
                            }
                          </ListItemIcon>
                          {
                            openDrawer
                              ? <ListItemText
                                primary={val.sidebarName}
                                className={classes.itemText + fontColorClasses}
                                disableTypography={true}
                              />
                              : null
                          }
                        </ListItem>
                      </NavLink>
                      : null
                  )
                }
              )}
          </List>
        </Collapse>
      </div>
    )
  }
}

SidebarSubMenu.propTypes = {
  classes: PropTypes.object.isRequired
};

function mapStateToProps(state, ownProps) {
  return {
    permissionLevel: state.user.permissionLevel
  };
}

export default compose(
  withStyles(sidebarStyle),
  connect(mapStateToProps, null)
)(SidebarSubMenu);
