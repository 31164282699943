import moment from 'moment'

export const getDateAndTime = (date) => {
  return dateToString(date) + " " + timeToString(date);
};

export const getToday = (internalFormat) => {
  return dateToString(new Date(), internalFormat);
};

export const getTomorrow = (internalFormat) => {
  let tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);

  return dateToString(tomorrow, internalFormat);
};

export const dateToString = (date, internalFormat) => {
  if (internalFormat) {
    return moment(date).format('YYYY-MM-DD');
  }
  return moment(date, 'YYYY-MM-DD').format('DD-MMM-YYYY');
};

export const timeToString = (time, internalFormat) => {
  let hour;
  let minutes;
  if (internalFormat) {
    let splitParts = time.split(':');
    hour = splitParts[0];
    minutes = splitParts[1];
  } else {
    hour = time.getHours();
    minutes = time.getMinutes();
    
    if(hour < 10) {
      hour = '0' + hour;
    }

    if(minutes < 10) {
      minutes = '0' + minutes;
    }
  }
  return hour + ":" + minutes;
};

export const dateAndTimeToString = (dateAndTime, internalFormat) => {
  let splitParts = dateAndTime.split(' ');
  let date = splitParts[0];
  let time = splitParts[1];
  return dateToString(date, internalFormat) + " " + timeToString(time, true);
};

export const isProjectFinished = (projectObject) => {
  return projectObject && (projectObject.projectStatus === "COMPLETED" || projectObject.projectStatus === "CANCELLED");
};

export const findObjectById = (array, id, idFieldName) => {
  let elementIndex;
  if(idFieldName) {
    elementIndex = array.findIndex(element => element[idFieldName] === id);
  } else {
    elementIndex = array.findIndex(element => element.id === id);
  }
  return array[elementIndex];
};

export const findObjectByIdAndReplace = (array, updatedElement, idFieldName, addIfNotFound) => {
  let elementIndex;
  let returningArray = Object.assign([], array);

  if(idFieldName) {
    elementIndex = returningArray.findIndex(element => element[idFieldName] === updatedElement[idFieldName]);
  } else {
    elementIndex = returningArray.findIndex(element => element.id === updatedElement.id);
  }
  if(addIfNotFound && elementIndex === -1) {
    returningArray.push(updatedElement);
  } else if(elementIndex > -1) {
    returningArray.splice(elementIndex, 1, updatedElement);
  }
  return returningArray;
};

export const findObjectByIdAndRemove = (array, idOrElement, idFieldName) => {
  
  let returningArray = Object.assign([], array);
  let id = idFieldName && idOrElement[idFieldName]
    ? idOrElement[idFieldName]
    : idOrElement.id
      ? idOrElement.id
      : idOrElement; //is it an element? if not, it's an id

  let elementIndex;
  if(idFieldName) {
    elementIndex = returningArray.findIndex(element => element[idFieldName] === id);
  } else {
    elementIndex = returningArray.findIndex(element => element.id === id);
  }
  if(elementIndex > -1) {
    returningArray.splice(elementIndex, 1);
  }
  return returningArray;
};

export const getFirstElementOfHashPath = (inputPath) => {
  let path = inputPath ? inputPath : window.location.hash;
  let splitParts = path.split('/');
  return splitParts[1];
};

export const getLastElementOfHashPath = (inputPath) => {
  let path = inputPath ? inputPath : window.location.hash;
  let splitParts = path.split('/');
  return splitParts[splitParts.length - 1];
};

export const deepClone = (src) => {
  if(src && src instanceof Object) {
    return JSON.parse(JSON.stringify(src))
  } else {
    return src;
  }
};

/**
 * The location is checked and confirmed against the last element if the URL path
 * If the locationToCheckAgainst is 'id', then it checks if the last element of the URL is an id
 */
export const confirmLocation = (locationToCheckAgainst, inputPath) => {
  let path = inputPath ? inputPath : window.location.hash;
  let splitParts = path.split('/');
  if(locationToCheckAgainst !== "id") {
    return splitParts[splitParts.length - 1] === locationToCheckAgainst;
  } else {
    return !isNaN(parseInt(splitParts[splitParts.length - 1], 10));
  }
};

export const getWordsFromString = (inputString) => {
  let splitWords = inputString.split(' ');
  return splitWords;
};

export const concatWordsFromString = (inputString) => {
  let splitWords = inputString.split(' ');
  let newWord = '';
  splitWords.forEach(word => {
    newWord = newWord + word;
  });
  return newWord;
};

export const replaceUnderscoresWithSpacesFromString = (inputString) => {
  let splitWords = inputString.split('_');
  let newWord = splitWords[0];
  for(let i = 1; i < splitWords.length; i++) {
    if(splitWords[i] !== splitWords[splitWords.length]) {
      newWord = newWord  + " " + splitWords[i];
    }
  }
  return newWord;
};

export const getIdFromPath = (inputPath) => {
  let path = inputPath ? inputPath : window.location.hash;
  let splitParts = path.split('/');
  return splitParts.find(part => !isNaN(parseInt(part)));
};

export const getPathWithoutLastElement = (inputPath) => {
  let path = inputPath ? inputPath : window.location.hash;
  let splitParts = path.split('/');
  let targetPath = "";
  for(let i = 1; i < splitParts.length-1; i++) {
    targetPath += '/' + splitParts[i];
  }
  return targetPath;
};

export const getPathWithoutHash = (inputPath) => {
  let pathWithHash = inputPath ? inputPath : window.location.hash;
  let splitParts = pathWithHash.split('/');
  let path = "";
  for(let i = 1; i < splitParts.length; i++) {
    path += "/" + splitParts[i];
  }
  return path;
};

export const objIsEmpty = (obj) => {
  for(let key in obj) {
      if(obj.hasOwnProperty(key))
          return false;
  }
  return true;
}

export const splitStringToArray = (str) => {
    const separators = [';', ',', '\\s'];
    let arr = str.split(new RegExp(separators.join('|'), 'g'));
    for (let i = 0; i<arr.length; i++) {
        if (arr[i] === "") {
            arr.splice(i, 1);
        }
    }
    return arr;
};

export const aggregateArrayValues = (arr) => {
  let acc = arr.reduce((acc, val) => acc.set(val, 1 + (acc.get(val) || 0)), new Map());
  return acc;
};

export function getParam( name, search ) {
  if (!search) search = window.location.search;
  return (new URLSearchParams(search)).get(name);
}

export function removeUrlParameters(url) {
  let indexOfParameterStart = url.indexOf("?");
  if(indexOfParameterStart !== -1) {
    url = url.substring(0, indexOfParameterStart);
  }
  return url;
}

export function getDateFormat() {
  const formats = {
    "ar-SA" : "dd/MM/yy",
    "bg-BG" : "dd.M.yyyy",
    "ca-ES" : "dd/MM/yyyy",
    "zh-TW" : "yyyy/M/d",
    "cs-CZ" : "d.M.yyyy",
    "da-DK" : "dd-MM-yyyy",
    "de-DE" : "dd.MM.yyyy",
    "el-GR" : "d/M/yyyy",
    "en-US" : "M/d/yyyy",
    "fi-FI" : "d.M.yyyy",
    "fr-FR" : "dd/MM/yyyy",
    "he-IL" : "dd/MM/yyyy",
    "hu-HU" : "yyyy. MM. dd.",
    "is-IS" : "d.M.yyyy",
    "it-IT" : "dd/MM/yyyy",
    "ja-JP" : "yyyy/MM/dd",
    "ko-KR" : "yyyy-MM-dd",
    "nl-NL" : "d-M-yyyy",
    "nb-NO" : "dd.MM.yyyy",
    "pl-PL" : "yyyy-MM-dd",
    "pt-BR" : "d/M/yyyy",
    "ro-RO" : "dd.MM.yyyy",
    "ru-RU" : "dd.MM.yyyy",
    "hr-HR" : "d.M.yyyy",
    "sk-SK" : "d. M. yyyy",
    "sq-AL" : "yyyy-MM-dd",
    "sv-SE" : "yyyy-MM-dd",
    "th-TH" : "d/M/yyyy",
    "tr-TR" : "dd.MM.yyyy",
    "ur-PK" : "dd/MM/yyyy",
    "id-ID" : "dd/MM/yyyy",
    "uk-UA" : "dd.MM.yyyy",
    "be-BY" : "dd.MM.yyyy",
    "sl-SI" : "d.M.yyyy",
    "et-EE" : "d.MM.yyyy",
    "lv-LV" : "yyyy.MM.dd.",
    "lt-LT" : "yyyy.MM.dd",
    "fa-IR" : "MM/dd/yyyy",
    "vi-VN" : "dd/MM/yyyy",
    "hy-AM" : "dd.MM.yyyy",
    "az-Latn-AZ" : "dd.MM.yyyy",
    "eu-ES" : "yyyy/MM/dd",
    "mk-MK" : "dd.MM.yyyy",
    "af-ZA" : "yyyy/MM/dd",
    "ka-GE" : "dd.MM.yyyy",
    "fo-FO" : "dd-MM-yyyy",
    "hi-IN" : "dd-MM-yyyy",
    "ms-MY" : "dd/MM/yyyy",
    "kk-KZ" : "dd.MM.yyyy",
    "ky-KG" : "dd.MM.yy",
    "sw-KE" : "M/d/yyyy",
    "uz-Latn-UZ" : "dd/MM yyyy",
    "tt-RU" : "dd.MM.yyyy",
    "pa-IN" : "dd-MM-yy",
    "gu-IN" : "dd-MM-yy",
    "ta-IN" : "dd-MM-yyyy",
    "te-IN" : "dd-MM-yy",
    "kn-IN" : "dd-MM-yy",
    "mr-IN" : "dd-MM-yyyy",
    "sa-IN" : "dd-MM-yyyy",
    "mn-MN" : "yy.MM.dd",
    "gl-ES" : "dd/MM/yy",
    "kok-IN" : "dd-MM-yyyy",
    "syr-SY" : "dd/MM/yyyy",
    "dv-MV" : "dd/MM/yy",
    "ar-IQ" : "dd/MM/yyyy",
    "zh-CN" : "yyyy/M/d",
    "de-CH" : "dd.MM.yyyy",
    "en-GB" : "dd/MM/yyyy",
    "es-MX" : "dd/MM/yyyy",
    "fr-BE" : "d/MM/yyyy",
    "it-CH" : "dd.MM.yyyy",
    "nl-BE" : "d/MM/yyyy",
    "nn-NO" : "dd.MM.yyyy",
    "pt-PT" : "dd-MM-yyyy",
    "sr-Latn-CS" : "d.M.yyyy",
    "sv-FI" : "d.M.yyyy",
    "az-Cyrl-AZ" : "dd.MM.yyyy",
    "ms-BN" : "dd/MM/yyyy",
    "uz-Cyrl-UZ" : "dd.MM.yyyy",
    "ar-EG" : "dd/MM/yyyy",
    "zh-HK" : "d/M/yyyy",
    "de-AT" : "dd.MM.yyyy",
    "en-AU" : "d/MM/yyyy",
    "es-ES" : "dd/MM/yyyy",
    "fr-CA" : "yyyy-MM-dd",
    "sr-Cyrl-CS" : "d.M.yyyy",
    "ar-LY" : "dd/MM/yyyy",
    "zh-SG" : "d/M/yyyy",
    "de-LU" : "dd.MM.yyyy",
    "en-CA" : "dd/MM/yyyy",
    "es-GT" : "dd/MM/yyyy",
    "fr-CH" : "dd.MM.yyyy",
    "ar-DZ" : "dd-MM-yyyy",
    "zh-MO" : "d/M/yyyy",
    "de-LI" : "dd.MM.yyyy",
    "en-NZ" : "d/MM/yyyy",
    "es-CR" : "dd/MM/yyyy",
    "fr-LU" : "dd/MM/yyyy",
    "ar-MA" : "dd-MM-yyyy",
    "en-IE" : "dd/MM/yyyy",
    "es-PA" : "MM/dd/yyyy",
    "fr-MC" : "dd/MM/yyyy",
    "ar-TN" : "dd-MM-yyyy",
    "en-ZA" : "yyyy/MM/dd",
    "es-DO" : "dd/MM/yyyy",
    "ar-OM" : "dd/MM/yyyy",
    "en-JM" : "dd/MM/yyyy",
    "es-VE" : "dd/MM/yyyy",
    "ar-YE" : "dd/MM/yyyy",
    "en-029" : "MM/dd/yyyy",
    "es-CO" : "dd/MM/yyyy",
    "ar-SY" : "dd/MM/yyyy",
    "en-BZ" : "dd/MM/yyyy",
    "es-PE" : "dd/MM/yyyy",
    "ar-JO" : "dd/MM/yyyy",
    "en-TT" : "dd/MM/yyyy",
    "es-AR" : "dd/MM/yyyy",
    "ar-LB" : "dd/MM/yyyy",
    "en-ZW" : "M/d/yyyy",
    "es-EC" : "dd/MM/yyyy",
    "ar-KW" : "dd/MM/yyyy",
    "en-PH" : "M/d/yyyy",
    "es-CL" : "dd-MM-yyyy",
    "ar-AE" : "dd/MM/yyyy",
    "es-UY" : "dd/MM/yyyy",
    "ar-BH" : "dd/MM/yyyy",
    "es-PY" : "dd/MM/yyyy",
    "ar-QA" : "dd/MM/yyyy",
    "es-BO" : "dd/MM/yyyy",
    "es-SV" : "dd/MM/yyyy",
    "es-HN" : "dd/MM/yyyy",
    "es-NI" : "dd/MM/yyyy",
    "es-PR" : "dd/MM/yyyy",
    "am-ET" : "d/M/yyyy",
    "tzm-Latn-DZ" : "dd-MM-yyyy",
    "iu-Latn-CA" : "d/MM/yyyy",
    "sma-NO" : "dd.MM.yyyy",
    "mn-Mong-CN" : "yyyy/M/d",
    "gd-GB" : "dd/MM/yyyy",
    "en-MY" : "d/M/yyyy",
    "prs-AF" : "dd/MM/yy",
    "bn-BD" : "dd-MM-yy",
    "wo-SN" : "dd/MM/yyyy",
    "rw-RW" : "M/d/yyyy",
    "qut-GT" : "dd/MM/yyyy",
    "sah-RU" : "MM.dd.yyyy",
    "gsw-FR" : "dd/MM/yyyy",
    "co-FR" : "dd/MM/yyyy",
    "oc-FR" : "dd/MM/yyyy",
    "mi-NZ" : "dd/MM/yyyy",
    "ga-IE" : "dd/MM/yyyy",
    "se-SE" : "yyyy-MM-dd",
    "br-FR" : "dd/MM/yyyy",
    "smn-FI" : "d.M.yyyy",
    "moh-CA" : "M/d/yyyy",
    "arn-CL" : "dd-MM-yyyy",
    "ii-CN" : "yyyy/M/d",
    "dsb-DE" : "d. M. yyyy",
    "ig-NG" : "d/M/yyyy",
    "kl-GL" : "dd-MM-yyyy",
    "lb-LU" : "dd/MM/yyyy",
    "ba-RU" : "dd.MM.yy",
    "nso-ZA" : "yyyy/MM/dd",
    "quz-BO" : "dd/MM/yyyy",
    "yo-NG" : "d/M/yyyy",
    "ha-Latn-NG" : "d/M/yyyy",
    "fil-PH" : "M/d/yyyy",
    "ps-AF" : "dd/MM/yy",
    "fy-NL" : "d-M-yyyy",
    "ne-NP" : "M/d/yyyy",
    "se-NO" : "dd.MM.yyyy",
    "iu-Cans-CA" : "d/M/yyyy",
    "sr-Latn-RS" : "d.M.yyyy",
    "si-LK" : "yyyy-MM-dd",
    "sr-Cyrl-RS" : "d.M.yyyy",
    "lo-LA" : "dd/MM/yyyy",
    "km-KH" : "yyyy-MM-dd",
    "cy-GB" : "dd/MM/yyyy",
    "bo-CN" : "yyyy/M/d",
    "sms-FI" : "d.M.yyyy",
    "as-IN" : "dd-MM-yyyy",
    "ml-IN" : "dd-MM-yy",
    "en-IN" : "dd-MM-yyyy",
    "or-IN" : "dd-MM-yy",
    "bn-IN" : "dd-MM-yy",
    "tk-TM" : "dd.MM.yy",
    "bs-Latn-BA" : "d.M.yyyy",
    "mt-MT" : "dd/MM/yyyy",
    "sr-Cyrl-ME" : "d.M.yyyy",
    "se-FI" : "d.M.yyyy",
    "zu-ZA" : "yyyy/MM/dd",
    "xh-ZA" : "yyyy/MM/dd",
    "tn-ZA" : "yyyy/MM/dd",
    "hsb-DE" : "d. M. yyyy",
    "bs-Cyrl-BA" : "d.M.yyyy",
    "tg-Cyrl-TJ" : "dd.MM.yy",
    "sr-Latn-BA" : "d.M.yyyy",
    "smj-NO" : "dd.MM.yyyy",
    "rm-CH" : "dd/MM/yyyy",
    "smj-SE" : "yyyy-MM-dd",
    "quz-EC" : "dd/MM/yyyy",
    "quz-PE" : "dd/MM/yyyy",
    "hr-BA" : "d.M.yyyy.",
    "sr-Latn-ME" : "d.M.yyyy",
    "sma-SE" : "yyyy-MM-dd",
    "en-SG" : "d/M/yyyy",
    "ug-CN" : "yyyy-M-d",
    "sr-Cyrl-BA" : "d.M.yyyy",
    "es-US" : "M/d/yyyy"
  };

  return formats[navigator.language] || 'dd/MM/yyyy';
}

/**
 * @param obj The object for which we want to find the element at the specified path
 * @param path The path where the element is found, and can be separated by dots (eg. location.city)
 * @returns The element of the object, at the specified path
 */

export function resolveObjectPath(obj, path) {
  path = path.split('.');
  let current = obj;
  if(typeof current !== 'object') return undefined;
  if (path.length) {
    path.map(word => {
      let index = getIndexFromString(word);
      if (index) {
        word = word.split('[')[0];
        current = current[word][0];
      } else {
        current = current[word];
      }
      return current;
    });
  }
  return current;
}

export function getIndexFromString(str) {
  let index = str.substring(
    str.lastIndexOf("[") + 1, 
    str.lastIndexOf("]"));
  return index;
}

export function capitalizeFirstLetterOfEachWord(str) {
  const captialize = words => words.split(' ').map( w =>  w.substring(0,1).toUpperCase()+ w.substring(1)).join(' ');
  return captialize(str.toLowerCase());
}

export function capitalizeFirstLetter(str) {
  let capitalizedString = str.toLowerCase();
  if(str && str.length > 0) {
    return capitalizedString.charAt(0).toUpperCase() + capitalizedString.substr(1);
  } else {
    return capitalizedString;
  }
}

export function convertToSnakeCase(str) {
  if(str && str.length > 0) {
    str = str.toLowerCase();
    str = str.replace(/ /g, "_");
    return str;
  } else {
    return str;
  }
}

export function filterObjectByStartsWith(objectArray, fieldNameToFilter, filterValue) {
  return objectArray.filter(obj => {
    return obj[fieldNameToFilter].startsWith(filterValue);
  })
}

export function filterObjectByIncludes(objectArray, fieldNameToFilter, filterValue) {
  return objectArray.filter(obj => {
    return obj[fieldNameToFilter].includes(filterValue);
  })
}

export function shortenName(name, acceptedCharacters) {
  if(name) {
    let splitNames = name.split(" ");
    if(splitNames.length && splitNames[0]) {
      if(splitNames[0].length <= acceptedCharacters) {
        return splitNames[0];
      } else {
        return splitNames[0].charAt(0) + ".";
      }
    }
  }
  return name;
}

export function shortenFullName(name, acceptedCharacters) {
  if(name) {
    let returningName = "";
    let splitNames = name.split(" ");
    if(splitNames.length) {
      if(splitNames[0]) {
        returningName += shortenName(splitNames[0], acceptedCharacters);
      }
      if(splitNames.length > 1) {
        returningName += " ";
        returningName += shortenName(splitNames[splitNames.length-1], acceptedCharacters)
      }
    }
    return returningName;
  }
  return name;
}

export function compareStringLengths(value1, value2) {
  if((value1+"").length > (value2+"").length) {
    return 1;
  } else if((value1+"").length < (value2+"").length) {
    return -1;
  } else {
    return 0;
  }
}

export function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0)
      return order;
    else
      return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

export function cmpFunction(order, orderBy) {

  function desc(a, b, orderBy) {
    a = resolveObjectPath(a, orderBy);
    b = resolveObjectPath(b, orderBy);
    if(order === 'desc') {
      if(isEmptyValue(a)) return 1;
      if(isEmptyValue(b)) return -1;
    } else {                                                                   
      if(isEmptyValue(a)) return -1;
      if(isEmptyValue(b)) return 1;
    }
    if(typeof a === 'string' && typeof b === 'string') {
      return -a.localeCompare(b, undefined, {sensitivity: 'accent'});
    } else {
      if(b < a) return -1;
      else if(b > a) return 1;
      else return 0;
    }
  }

  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

export function isEmptyValue(data) {
  return data === null || data === "undefined" || data === "" || data === "--";
}
