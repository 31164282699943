import * as React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import compose from "recompose/compose";
import {connect} from "react-redux";
import {Icon, Table, Typography} from "@material-ui/core";
import {cmpFunction, stableSort} from "../utils/utilFunctions";
import EnhancedTableHead from "../components/Table/EnhancedTableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import * as mainActions from "../actions/mainActions";
import * as appActions from "../actions/appActions";
import {bindActionCreators} from "redux";
import Grid from "@material-ui/core/Grid";
import Card from "../components/Card/Card";
import CardContent from "@material-ui/core/CardContent";
import TableCell from "@material-ui/core/TableCell";

const style = {
  mainContainer: {
    position: "relative",
    height: "100%"
  },
  viewSelectContainer: {
    position: "absolute",
    right: "0"
  },
  gridItem: {
    width: "inherit",
    padding: "10px"
  },
  table: {
    marginBottom: "0",
    width: "100%",
    maxWidth: "100%",
    backgroundColor: "transparent",
    borderSpacing: "0",
    borderCollapse: "collapse"
  },
  tableHeadCell: {
    color: "inherit",
    fontSize: "1.2em",
    fontWeight: "500 !important",
    paddingLeft: "8px !important"
  },
  tableCell: {
    padding: "8px",
    lineHeight: "1.42857143",
    verticalAlign: "middle",
    color: `#000 !important`,
  },
  tableRow: {
    "&:hover,&:focus": {
      background: "#ebebeb"
    }
  },
  tableResponsive: {
    width: "100%",
    marginTop: 3,
    overflowX: "auto"
  }
};

const tableHeader = [
  {id: "creationDate", numeric: false, displayPadding: true, label: "Order creation date"},
  {id: "netTotal", numeric: false, displayPadding: false, label: "Order value"},
  {id: "status", numeric: false, displayPadding: false, label: "Order status"}
];

class HomeView extends React.Component {

  state = {
    order: "desc",
    orderBy: "",
    stats: {}
  }

  stats = {};

  componentWillReceiveProps = () => {
    this.calculateStats();
  }

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = "desc";

    if (this.state.orderBy === property && this.state.order === "desc") {
      order = "asc";
    }

    this.setState({order, orderBy});
  };

  componentWillMount = async () => {
    await this.getOrders();
  }

  getOrders = async () => {
    await this.props.actions.getOrders();
    this.calculateStats();
  }

  calculateStats() {
    this.stats = {
      totalOrders: this.props.tableData.length,
      totalCompletedOrders: 0,
      percentage: this.props.partner.percentage,
      totalCompletedAmount: 0,
      totalPaidAmount: this.props.partner.totalPaid,
      totalLeftToPay: 0
    }

    this.props.tableData.forEach(order => {

      if(order.status === "COMPLETED") {
        this.stats.totalCompletedAmount += order.netTotal;
        this.stats.totalCompletedOrders++;
      }
    });

    this.stats.totalCompletedAmount = (this.stats.totalCompletedAmount * (this.props.partner.percentage / 100)).toFixed(2);
    this.stats.totalLeftToPay = (this.stats.totalCompletedAmount - this.stats.totalPaidAmount).toFixed(2);

    this.setState({stats: this.stats});
  }

  render() {
    const {classes, tableData} = this.props;
    const {order, orderBy, stats} = this.state;

    return (
      <div className={classes.mainContainer}>
        <Grid container>
          <Grid item md={12} className={classes.gridItem}>
            <Grid container justify="center">
              <Card style={{marginTop: 0}}>
                <CardContent>
                  <Typography variant={"h5"}>Stats</Typography>
                  <Typography variant={"body1"}>Total orders: <b>{stats.totalOrders}</b></Typography>
                  {/*<Typography variant={"body1"}>Total completed orders: <b>{stats.totalCompletedOrders}</b></Typography>*/}
                  <Typography variant={"body1"}>Partner percentage: <b>{stats.percentage}</b></Typography>
                  <Typography variant={"body1"}>Total amount made by partnership (counting completed orders only): <b>{stats.totalCompletedAmount}</b></Typography>
                  <Typography variant={"body1"}>Total amount paid so far: <b>{stats.totalPaidAmount}</b></Typography>
                  <Typography variant={"body1"}>Total amount pending to be paid: <b>{stats.totalLeftToPay}</b></Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item md={12} className={classes.gridItem} style={{paddingTop: 0}}>
            <Grid container justify="center">
              <Card style={{marginTop: "10px"}}>
                <CardContent style={{overflow: "scroll", maxHeight: "650px"}}>

                  <Icon onClick={this.getOrders} style={{position: "absolute", right: "10px", top: "10px", zIndex: "99", cursor: "pointer"}}>{"refresh"}</Icon>
                  <Table className={classes.table}>
                    <EnhancedTableHead id={"enhanced_table_header"} onRequestSort={this.handleRequestSort}
                                       order={order} orderBy={orderBy} tableHeaderCells={tableHeader}/>
                    <TableBody>
                      {
                        stableSort(tableData, cmpFunction(order, orderBy)).map((row) => {
                          return (
                            <TableRow key={row.creationDate} className={classes.tableRow}
                                      id={"table_row_" + row.creationDate}>
                              <TableCell>{new Date(row.creationDate).toDateString()}</TableCell>
                              <TableCell>{row.netTotal}</TableCell>
                              <TableCell >{row.status}</TableCell>
                            </TableRow>
                          );
                        })
                      }
                    </TableBody>
                  </Table>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>

      </div>
    )
  }
}

function mapStateToProps(store, ownProps) {
  return {
    tableData: store.mainReducer.orders ? store.mainReducer.orders : [],
    partner: store.mainReducer.partner ? store.mainReducer.partner : {},
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign({}, mainActions, appActions), dispatch)
  }
}

export default compose(
  withStyles(style),
  connect(mapStateToProps, mapDispatchToProps)
)(HomeView);
