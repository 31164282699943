import * as React from "react";

class Delay extends React.Component {
  state = {
    hidden: true
  };

  componentWillMount = () => {
    this.timeOut = setTimeout(() => {
      this.show();
    }, this.props.timeout);
  };

  componentWillUnmount = () => {
    clearTimeout(this.timeOut);
  };

  show = () => {
    this.setState({hidden: false});
  };

  render() {
    if(this.state.hidden) {
      return null;
    } else {
      return this.props.children;
    }
  }
}

export default Delay;
