import * as React from "react";
import {Route, Switch} from "react-router-dom";
import {
  loginPath,
} from "../constants/paths";
import LoginView from "../views/Registration/LoginView";

class RegistrationRoutes extends React.Component {
  render() {
    return (
      <Switch>
        <Route path={loginPath} component={LoginView}/>
      </Switch>
    )
  }
}

export default RegistrationRoutes;
