import React from "react";
import ReactDOM from "react-dom";
import configureStore from "./store/configureStore";
import {Provider} from "react-redux";
import "./assets/css/material-dashboard-react.css?v=1.4.1";
import Amplify from "aws-amplify";
import awsConfig from "./config/awsConfig"
import App from "./App";

export const store = configureStore();

export function configureAmplify(cognitoMode) {

  Amplify.configure({
    Auth: {
      mandatorySignIn: true,
      region: awsConfig[cognitoMode].REGION,
      userPoolId: awsConfig[cognitoMode].USER_POOL_ID,
      userPoolWebClientId: awsConfig[cognitoMode].APP_CLIENT_ID
    },
    API: {
      endpoints: [
        {
          name: "notes",
          endpoint: awsConfig.apiGateway.URL,
          region: awsConfig.apiGateway.REGION
        },
      ]
    }
  });
}

configureAmplify("cognito");

ReactDOM.render(
  <Provider store={store}>
    <App/>
  </Provider>,
  document.getElementById("root")
);
