import {ORDERS_GET_SUCCESS} from "../actions/actionTypes";

let initialState = {
  orders: [],
  partner: {},
  activityLog: []
};
export default function mainReducer(state = initialState, action) {
  switch (action.type) {
    case ORDERS_GET_SUCCESS:
      let orders = action.orders.orders.map(order => {
        return {
          status: order.status,
          netTotal: order.netTotal,
          creationDate: (new Date(order.creationDate)).getTime()
        }
      })
      return {...state, orders: orders, partner: action.orders.partner};
    default:
      return state;
  }
}
