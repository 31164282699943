import {
  grayColor,
  primaryColorCardHeader,
  warningCardHeader,
  successCardHeader,
  dangerCardHeader,
  infoCardHeader,
  primaryCardHeader,
  roseCardHeader,
  primaryColor
} from "../../../../assets/jss/material-dashboard-react.jsx";

const cardIconStyle = {
  cardIcon: {
    "&$primaryColorCardHeader,&$warningCardHeader,&$successCardHeader,&$dangerCardHeader,&$infoCardHeader,&$primaryCardHeader,&$roseCardHeader": {
      borderRadius: "3px",
      backgroundColor: grayColor,
      padding: "calc(3px + 0.4vw)",
      marginTop: "-20px",
      marginRight: "15px",
      float: "left"
    }
  },
  primaryColorCardHeader,
  warningCardHeader,
  successCardHeader,
  dangerCardHeader,
  infoCardHeader,
  primaryCardHeader,
  roseCardHeader,
  grayColor,
  primaryColor
};

export default cardIconStyle;
