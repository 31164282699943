import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Icon from "@material-ui/core/Icon";
import cardIconStyle from "../../assets/jss/material-dashboard-react/components/cardIconStyle";
import Tooltip from "@material-ui/core/Tooltip";
import PropTypes from "prop-types";

const style = {
  common: {
    color: cardIconStyle.grayColor,
    margin: "0 5px",
    fontSize: "24px !important",
    "&:hover": {
      color: cardIconStyle.primaryColor,
      cursor: "pointer"
    }
  }
};

class CustomIcon extends React.Component {

  render() {
    const {className, tooltip, classes, icon, ...rest} = this.props;

    return (
      <Tooltip title={tooltip}>
        <Icon
          className={className ? className : classes.common}
          {...rest}
        >
          {icon}
        </Icon>
      </Tooltip>
    )
  };
}

CustomIcon.propTypes = {
  tooltip: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired
};

export default withStyles(style)(CustomIcon);
