import React from "react";
import PropTypes from "prop-types";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import withStyles from "@material-ui/core/styles/withStyles";
import {secondaryColor} from "../assets/jss/material-dashboard-react";
import Delay from "./Delay";

const style = {
  background: {
    width: "100%",
    height: "100%",
    position: "fixed",
    zIndex: 999999,
    backgroundColor: "rgb(255, 255, 255, .8)",
    top: 0,
    left: 0
  },
  content: {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)"
  },
  circularProgress: {
    "& svg": {
      color: secondaryColor
    },
    width: "90px !important",
    height: "90px !important",
    position: "fixed",
    top: "90%",
    left: "37%",
    zIndex: 999999,
  },
  circularProgressMobile: {
    "& svg": {
      color: secondaryColor
    },
    width: "40px !important",
    height: "40px !important",
    position: "fixed",
    top: "90%",
    left: "37%",
    zIndex: 999999,
  },
  loadingText: {
    color: "black"
  }
};

class CustomCircularProgress extends React.Component {

  render() {
    const {classes, show, mobileOpen} = this.props;
    if(show) {
      return (
        <Delay timeout={0}>
          <div className={classes.background}>
            <div className={classes.content}>
              <CircularProgress
                className={mobileOpen ? classes.circularProgressMobile : classes.circularProgress}
              />
            </div>
          </div>
        </Delay>
      )
    } else {
      return (<div/>)
    }
  }
}

CustomCircularProgress.propTypes = {
  show: PropTypes.bool.isRequired,
  mobileOpen: PropTypes.bool.isRequired,
};

export default withStyles(style)(CustomCircularProgress);
