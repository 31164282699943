import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Badge from '@material-ui/core/Badge';
import {whiteColor, primaryColor} from "../assets/jss/material-dashboard-react";

const style = () => ({
  badge: {
    backgroundColor: whiteColor,
    color: primaryColor,
    fontSize: "13px",
    minWidth: "0px",
    width: "5px",
    height: "5px",
    top: "40%",
    right: "48%",
  },
});
class CustomBadge extends React.Component {
  render() {
    const {classes, children, ...rest} = this.props;
    return(
      <div>
        <Badge
          classes={{ badge: classes.badge }}
          {...rest}>
          {children}
        </Badge>
      </div>
    );
  }
}

export default withStyles(style)(CustomBadge);
