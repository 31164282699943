import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Tooltip from "@material-ui/core/Tooltip";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import * as React from "react";
import * as PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import {fontColorPrimary} from "../../assets/jss/material-dashboard-react";
import CustomTableCell from "../CustomTableCell";

class EnhancedTableHead extends React.Component {

  createSortHandler = property => event => {
    if(this.props.onRequestSort && event && property) {
      this.props.onRequestSort(event, property);
    }
  };

  render() {
    const { order, orderBy, tableHeaderCells, padding, disableSorting } = this.props;

    return (
      <TableHead>
        <TableRow>
          {tableHeaderCells.map(
            (cell, key) => (
              <CustomTableCell
                style={{background: "white",  position: 'sticky', top: 0, zIndex: 10 }}
                id={"sort_cell_" + cell.id}
                key={key}
                padding={padding ? padding : ""}
                align={cell.numeric ? 'right' : 'left'}
                sortDirection={orderBy === cell.id ? order : false}
              >
                {
                  disableSorting
                  ?
                    <Typography
                      variant="title"
                      style={{color: fontColorPrimary, fontSize: "calc(10px + 0.5vw)"}}
                    >
                      {cell.label}
                    </Typography>

                  : cell.id
                      ? <Tooltip
                        title="Sort"
                        id={"sort_tooltip"}
                        placement={'bottom-start'}
                        enterDelay={300}
                      >
                        <TableSortLabel
                          id={"sort_lable"}
                          active={orderBy === cell.id}
                          direction={order}
                          onClick={this.createSortHandler(cell.id)}
                        >
                          <Typography
                            variant="title"
                            style={{color: orderBy === cell.id ? "black" : fontColorPrimary, fontSize: "calc(10px + 0.5vw)"}}
                          >
                            {cell.label}
                          </Typography>
                        </TableSortLabel>
                      </Tooltip>
                      : null
                }
              </CustomTableCell>
            ),
            this,
          )}
        </TableRow>
      </TableHead>
    );
  }
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  tableHeaderCells: PropTypes.array.isRequired,
  disableSorting: PropTypes.bool
};

export default EnhancedTableHead;
