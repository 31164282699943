import React from 'react';
import TableCell from '@material-ui/core/TableCell';

class CustomTableCell extends React.Component {


render () {
  const {children, content, padding, ...rest} = this.props;
  return (
    <TableCell
      padding={padding ? padding : 'none'}
      {...rest}
    >
      {content}
      {children ? children : null}
    </TableCell>
    );
  };
}  


export default CustomTableCell;