import {Auth} from "aws-amplify";
import {clearUserData} from "../actions/registrationActions";

export const signOut = async (historyParam, shouldReload) => {

  clearUserData();
  await Auth.signOut();
  if (historyParam) {
    historyParam.push('/login');
  }
  if (shouldReload) {
    window.location.reload();
  }
};

export const signUp = (userName, password) => {
  userName = userName ? userName.toLowerCase() : userName;
  return Auth.signUp({
      username: userName,
      password: password
    }
  )
};

export const isUserAuthenticated = async () => {
  return await Auth.currentAuthenticatedUser().then(currentAuthenticatedUser => {
    return !!currentAuthenticatedUser && !!currentAuthenticatedUser.attributes && !!currentAuthenticatedUser.attributes.email;
  }).catch(() => {
    return false;
  })
};

export const getAuthorizationToken = async () => {
  return await Auth.currentSession().then(authorization => {
    return authorization.getIdToken().getJwtToken();
  }).catch(() => {
    return {};
  });
}

export const getAuthorizationHeader = async () => {
  return await Auth.currentSession().then(authorization => {
    return {headers: {'Authorization': authorization.getIdToken().getJwtToken()}};
  }).catch(() => {
    return {};
  });
};

export const signIn = (userName, password, clientMetadata) => {
  userName = userName ? userName.toLowerCase() : userName;
  return Auth.signIn(userName, password, clientMetadata);
};

export const completeNewPassword = (user, password) => {
  return Auth.completeNewPassword(
    user,              // the Cognito User Object
    password,       // the new password
    {}
  );
};

export const changePassword = (oldPassword, newPassword) => {
  return Auth.currentAuthenticatedUser()
    .then(user => {
      return Auth.changePassword(user, oldPassword, newPassword);
    });
};

export default signOut;
