import * as types from "../actions/actionTypes";

let initialState = {
  user: "",
  permissionLevel: ""
};
export default function registrationReducer(state = initialState, action) {
  switch (action.type) {
    case types.USER_GET_SUCCESS:
      return {...state, user: action.user}
    case types.USER_TOKEN_VALIDATED:
      return {...state, email: action.user.email, permissionLevel: action.user.permissionLevel}
    //The user might have attempted to go to a restricted page, keep it in memory, and go there after logging in
    case types.REGISTRATION_SET_ATTEMPTED_LOCATION:
      return {...state, attemptedLocation: action.attemptedLocation};
    case types.USER_CLEAR:
      return {...state, user: ""}
    default:
      return state;
  }
}
