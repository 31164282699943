import React from 'react';
import Button from '@material-ui/core/Button';
import classNames from "classnames";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  primaryColor,
  primaryColorHover
} from "../../assets/jss/material-dashboard-react";
import PropTypes from "prop-types";
import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider";
import {createMuiTheme} from "@material-ui/core/styles";

const style = {
};

class CustomBlueButton extends React.Component {

  componentDidMount = () => {
    if(this.props.handleEnterKeypress) {
      window.addEventListener("keypress", this.handleKeypress, true);
    }
  };

  componentWillUnmount = () => {
    if(this.props.handleEnterKeypress) {
      window.removeEventListener("keypress", this.handleKeypress, true);
    }
  };

  handleKeypress = (event) => {
    if(!this.props.disabled) {
      // Only call the function if the charCode or keyCode is 13 (enter button)
      if (event.charCode === 13) {
        this.props.onClick();
      } else if (event.keyCode === 13) {
        this.props.onClick();
      }
    }
  };

  render () {
    const {label, disabled, classes, handleEnterKeypress, removeCommonStyling, ...rest} = this.props;

    const buttonStyle = classNames({
      [classes.common]: !removeCommonStyling,
    });

    const blueTheme = createMuiTheme({
      palette: {
        primary: {
          main: primaryColor,
          dark: primaryColorHover
        },
      },
      typography: {
        useNextVariants: true,
      }
    });

    return (
      <MuiThemeProvider theme={blueTheme}>
        <Button
          color={"primary"}
          className={buttonStyle}
          label={label}
          disabled={disabled}
          {...rest}
        >
          {label}
        </Button>
      </MuiThemeProvider>
    );
  }
}

CustomBlueButton.propTypes = {
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  handleEnterKeypress: PropTypes.bool, //If true, then adds listener to Enter keypress => when pressed, onClick function is called
  variant: PropTypes.oneOf(["text", "flat", "outlined", "contained", "raised", "fab", "extendedFab"])
};

export default withStyles(style)(CustomBlueButton);
