import * as types from "./actionTypes";
import { Auth } from "aws-amplify";

export function loginUser() {
  return function (dispatch) {
    return Auth.currentUserInfo().then(currentUser => {
      let user = currentUser.attributes.email;
      dispatch({type: types.USER_GET_SUCCESS, user});
    });
  };
}

export function clearUserData() {
  Auth.signOut({global: true}).then(() => {
    return function (dispatch) {
      dispatch({type: types.USER_CLEAR});
    };
  })
}

//The user might have attempted to go to a restricted page, keep it in memory, and go there after logging in
export function setAttemptedLocation(attemptedLocation) {
  return {type: types.REGISTRATION_SET_ATTEMPTED_LOCATION, attemptedLocation};
}
