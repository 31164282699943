import React, {Component} from "react";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField/TextField";
import withStyles from "@material-ui/core/styles/withStyles";
import {DatePicker, MuiPickersUtilsProvider} from "material-ui-pickers";
import DateFnsUtils from "@date-io/date-fns";

/**
 * This component is a wrapper for the TextField component
 * Beside the documented parameters, it also accepts an array of validation functions and an error object
 * Upon executing the validations, the first validation that fails will set the errorLabel field, which is then concatenated to the label text
 * When the first validation fails, the rest of the validations are no longer executed
 * Therefore, priority order of the validations should be set in the validation function array
 *
 * The error field should be passed as an object prop, therefore, the state change is propagated to the component that holds the form
 * The component that holds the form, should have an array of error objects, which can be used to get the overall form validation
 *
 * An array of Regex filters can also be passed in as a property.
 * If this array is present, then the field will only update as long as it matches the Regex filter.
 */


const style = {
  paragraph: {
    "& p": {
      textAlign: "right !important"
    }
  }
};

class CustomTextField extends Component {

  characterCounter = "";
  minCharacters = 0;

  constructor(props) {
    super(props);
    this.characterCounter = this.props.showCharacterCounter ? "( "+ this.props.value.length + "/" + this.props.maxCharacters + " )" : "";
    this.minCharacters = this.props.minCharacters ? this.props.minCharacters : (this.props.required ? 1 : 0);
  }

  /**
   * Executes the validations set in the validationFunctions array from props
   * The inputValue is not mandatory.
   * The function is either triggered from this component, or from validationFunctions
   * Therefore, the inputValue is passed, when calling the function from here
   * The inputValue is not passed, and the value is taken from the props, when called from validationFunctions
   */
  executeValidations = (inputValue) => {
    let value = inputValue === undefined ? this.props.value : inputValue;

    this.props.error.state = !this.props.validationFunctions
      ? false
      : !!this.props.validationFunctions.find(validationFunction => {
        this.props.error.label = validationFunction(value);
        return !!this.props.error.label;
      });

    /** If the iteration through the validation functions passes successfully, the below validations are also executed: */
    if(!this.props.error.state) {
      this.props.error.label = this.validateMaxLengthOfValue(value, this.props.maxCharacters);
      this.props.error.state = !!this.props.error.label;
    }

    /** If the max length validation (and all previous validations) pass, go ahead and validate min length */
    if(!this.props.error.state) {
      this.props.error.label = this.validateMinLengthOfValue(value, this.minCharacters);
      this.props.error.state = !!this.props.error.label;
    }
    return this.props.error.state;
  };

  componentWillReceiveProps = (nextProps) => {
    if(this.props.value !== nextProps.value && this.props.showCharacterCounter) {
      this.countCharacters(nextProps.value);
    }
    if(this.props.required !== nextProps.required) {
      this.minCharacters = nextProps.minCharacters ? nextProps.minCharacters : (nextProps.required ? 1 : 0);
    }
  };

  validateMinLengthOfValue = (value, minCharacters) => {
    if(minCharacters && ((typeof value.trim === "function" && value.trim().length < minCharacters) || value.length < minCharacters)) {
      if(minCharacters === 1) {
        return " "; //it's a required field, the message already shows the "required" star
      } else {
        return " - must contain at least " + minCharacters + " characters";
      }
    } else {
      return "";
    }
  };

  validateMaxLengthOfValue = (value, maxCharacters) => {
    if(maxCharacters && value.length > maxCharacters) {
      return " - exceeds " + maxCharacters + " characters";
    } else {
      return "";
    }
  };

  isValidByRegexRule = (value) => {
    if(this.props.regexFilters && this.props.regexFilters.length >= 1) {
      return !!this.props.regexFilters.find(regexFilter => {
        if(typeof regexFilter === "string") {
          return new RegExp(regexFilter).test(value);
        }
        return regexFilter.test(value);
      });
    } else {
      return true;
    }
  };

  countCharacters = (value) => {
    this.characterCounter = "( "+ value.length + "/" + this.props.maxCharacters + " )";
  };

  isValidByFunctionRule = (value) => {
    if(this.props.functionFilters && this.props.functionFilters.length >= 1) {
      return !!this.props.functionFilters.find(functionFilter => {
        return functionFilter(value);
      });
    } else {
      return true;
    }
  };

  shouldApplyFilter(event) {
    return !(this.isValidByRegexRule(event.target.value) && this.isValidByFunctionRule(event.target.value));
  }

  handleChange(event) {
    if(this.props.showCharacterCounter) {
      this.countCharacters(event.target.value);
    }
    this.props.handleChange(event);
  }

  validateAndAssign = (event) => {
    this.executeValidations(event.target.value);
    if(!this.shouldApplyFilter(event)) {
      this.handleChange(event);
    }
  };

  render() {
    const {label, error, handleChange, regexFilters, functionFilters,
      validationFunctions, maxCharacters, minCharacters, showCharacterCounter,
      classes, type, ...rest} = this.props;

    if(type !== "date") {
      return (
        <TextField
          label={label + (error ? error.label : "")}
          onChange={this.validateAndAssign}
          error={error ? error.state : false}
          className={classes.paragraph}
          helperText={this.characterCounter}
          type={type}
          {...rest}
        />
      )
    } else {
      return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <DatePicker
            format={'dd-MMM-yyyy'}
            label={label + (error ? error.label : "")}
            onChange={(date) => handleChange(date, this.props.name)}
            {...rest}
          />
        </MuiPickersUtilsProvider>
      )
    }
  }
}

CustomTextField.propTypes = {
  error: PropTypes.shape({
    label: PropTypes.string.isRequired,
    state: PropTypes.bool.isRequired
  }),
  handleChange: PropTypes.func,
  regexFilters: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.object/* RegExp type not supported */])),
  functionFilters: PropTypes.arrayOf(PropTypes.func.isRequired),
  validationFunctions: PropTypes.array,
  showCharacterCounter: PropTypes.bool,
  minCharacters: PropTypes.number,
  maxCharacters: PropTypes.number
};

export default withStyles(style)(CustomTextField);
