import React from "react";
import {HashRouter, Route, Switch} from "react-router-dom";
import history from "./routes/history";
import indexRoutes from "./routes";
import { Auth } from "aws-amplify";
import connect from "react-redux/es/connect/connect";
import {bindActionCreators} from "redux";
import * as registrationActions from "./actions/registrationActions";
import {loginPath} from "./constants/paths";
import {getPathWithoutHash} from "./utils/utilFunctions";

class App extends React.Component {

  state = {};

  constructor(props) {
    super(props);

    this.state = {
      isAuthenticating: true
    };
  }

  componentDidMount = async () => {
    try {
      if(await Auth.currentSession()) { //if there is a session
        if(!this.props.currentUserStatus) { //but the redux store doesn't have the user info
          //throw {} // so the user has to log in every time
          await this.props.actions.loginUser(); //get it from the session and put it into store
        } else {
          history.push("/#" + loginPath);
        }
      }
    } catch(e) {
      //Redirect the user to the login page, if there isn't a current session (unless, we are already on the login/registration page
      if(!window.location.hash.includes(loginPath)) {
        //The user might have attempted to go to a restricted page, keep it in memory, and go there after logging in
        this.props.actions.setAttemptedLocation(getPathWithoutHash());
        history.push("/#" + loginPath);
      }
    }
    this.setState({isAuthenticating: false});
  };

  render() {
    return (
      !this.state.isAuthenticating &&
      <HashRouter history={history}>
        <Switch>
          {indexRoutes.map((prop, key) => {
            return (
              <Route path={prop.path} component={prop.component} key={key}/>
            );
          })}
        </Switch>
      </HashRouter>
    )
  }
}

function mapStateToProps(store) {
  return {
    currentUserStatus: store.registrationReducer.user
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign({}, registrationActions), dispatch)
  }
}

App.propTypes = {
  //myProp: PropTypes.string.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
