import {emailRule, passwordRule} from "./regexRules";

/**
 *  Validation functions
 */

export const validateEmailFormatOfValue = (value) => {
  if(value && !emailRule.test(value)) {
    return " - invalid email address";
  } else {
    return "";
  }
};

export const validateDateMustBeAfterTomorrow = (value) => {
  let date = Date.parse(value);
  let tomorrowsDate = new Date();

  if(date < tomorrowsDate) {
    return " - is in the past";
  } else {
    return "";
  }
};

export const validateDateYearTooSmall = (value) => {
  let dateToYear = new Date(value);
  let year = dateToYear.getFullYear();
  if (year < 2000) {
    return " - min year: 2000";
  } else {
    return "";
  }
};

export const validateDateYearTooBig = (value) => {
  let dateToYear = new Date(value);
  let year = dateToYear.getFullYear();
  if (year > 2100) {
    return " - max year: 2100";
  } else {
    return "";
  }
};

export const validateDateFormat = (value) => {
  let dateToYear = new Date(value);
  let year = dateToYear.getFullYear();
  if (isNaN(year)) {
    return " - invalid date"
  } else { 
    return ""
  }
};

export const validatePasswordFormat = (value) => {
  if(value && !passwordRule.test(value)) {
    return " - at least 8 characters, 1 lower, 1 upper, 1 special";
  } else {
    return "";
  }
};

/**
 * Validation function groups
 */

export const dateFieldValidationFunctions = [validateDateYearTooSmall, validateDateYearTooBig, validateDateFormat];

export const textFieldRequiredEmailValidationFunctions = [validateEmailFormatOfValue];

export const textFieldRequiredPasswordMinEightOneUpperOneLowerOneNumberOneSpecial = [validatePasswordFormat];

/**
 * Validation to be performed on all error states, to return a final form state
 */

export const isFormValid = (errorStates) => {
  return !Object.keys(errorStates).find(key => {
    return errorStates[key].state;
  });
};

/**
 * Function which forces field validations, even if they have not been edited
 * To be used when pressing the save button
 * The input parameter is taken from the form component, which must hold an array of references from every field that needs validation
 * The fields to be validated will create a reference, by adding the following prop to the CustomTextField component:
 * ref={instance => {this.fieldName = instance;}}
 * @param textFieldReferences
 */
export const triggerValidationsForAllFields = (textFieldReferences) => {
  textFieldReferences.forEach(textFieldReference => {
    textFieldReference.executeValidations();
  });
};
