export default {
  apiGateway: {
    REGION: "eu_central_1",
    URL: "https://api.mbtools.me"
  },
  cognito: {
    REGION: "eu_central_1",
    USER_POOL_ID: "eu-central-1_59XiubPG5",
    APP_CLIENT_ID: "7d6st7tueeq3nm6re81aa9hglk"
  }
}
