import * as types from "../actions/actionTypes";
import {drawerWidth} from "../assets/jss/material-dashboard-react";

const initialState = {
  isLoading: false,
  drawerWidth: drawerWidth,
  message: {
    content: "",
    show: false,
    type: "info"
  },
  projectGridView: true,
  employeeGridView: true,
  toolGridView: true,
  addToolToCartModeActive: false,
  addEmployeeToCartModeActive: false,
  assignedEmployeesFromProjectModeActive: false,
  assignedToolsFromProjectModeActive: false
};

export default function appReducer(state = initialState, action) {
  switch (action.type) {
    case types.APP_SET_LOADING:
      return {...state, isLoading: action.loading};
    case types.APP_SET_MESSAGE:
      let message = !action.message ? initialState.message : {
        content: action.message,
        show: true,
        type: "info"
      };
      return {...state, message: message};
    case types.APP_SWITCH_DRAWER_WIDTH:
      return {...state, drawerWidth: action.drawerWidth};
    case types.APP_PROJECT_CHANGE_VIEW:
      return {...state, projectGridView: action.gridView};
    case types.APP_EMPLOYEE_CHANGE_VIEW:
      return {...state, employeeGridView: action.gridView};
    case types.APP_TOOL_CHANGE_VIEW:
      return {...state, toolGridView: action.gridView};
    case types.APP_ADD_EMPLOYEE_TO_CART_MODE:
      return {...state, addEmployeeToCartModeActive: action.addEmployeeToCartModeActive};
    case types.APP_ADD_TOOL_TO_CART_MODE:
      return {...state, addToolToCartModeActive: action.addToolToCartModeActive};
    case types.APP_ASSIGNED_EMPLOYEES_FROM_PROJECT_MODE:
      return {...state, assignedEmployeesFromProjectModeActive: action.assignedEmployeesFromProjectModeActive}
    case types.APP_ASSIGNED_TOOLS_FROM_PROJECT_MODE:
      return {...state, assignedToolsFromProjectModeActive: action.assignedToolsFromProjectModeActive}
    default:
      return state;
  }
}
