import * as appActions from "./appActions";
import {errorMessages} from "./errorMessages";
import signOut from "../utils/authUtils";

export async function handleFailure(error, dispatch, moduleName) {
  if(error.response) {
    let errFound = false;
    if(error.response.status === 403) {
      await signOut(null, true);
      dispatch(appActions.setMessage("An error occurred while loading " + moduleName + " related stuff: Bad request - no partnership detected!"));
      return;
    }
    if(!errFound) {
      if(error.response.data.message) {
        dispatch(appActions.setMessage("An error occurred while loading " + moduleName + " related stuff: " + error.response.data.message + " Please refresh and try again."));
      } else if (error.response.data.error){
        dispatch(appActions.setMessage("An error occurred while loading " + moduleName + " related stuff: " + error.response.data.error + " Please refresh and try again."));
      } else {
        dispatch(appActions.setMessage("An error occurred while loading " + moduleName + " related stuff. Please refresh and try again."));
      }
    }
  }
  dispatch(appActions.setLoading(false));
  throw(error);
}
