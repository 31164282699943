import React from "react";
import {withStyles} from "@material-ui/core";

const style = {
  main: {
    top: "35%",
    left: "50%",
    transform: "translate(-50%)",
    position: "relative",
    display: "block",
    width: "60%"
  }
};

export const Logo = (props) => (
  <img
    style={props.style}
    className={props.classes.main}
    src={""}
    alt={""}
  />
);

export default withStyles(style)(Logo);
