import { createStore, applyMiddleware, compose } from "redux";
import rootReducer from "../reducers";
import reduxImmutableStateInvariant from 'redux-immutable-state-invariant';
import thunk from 'redux-thunk';

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const middlewares = [thunk, reduxImmutableStateInvariant()];

export default function configureStore() {
  return createStore(
    rootReducer,
    composeEnhancer(applyMiddleware(...middlewares))
  );
}
