import React from "react";
import CardContent from "@material-ui/core/CardContent/CardContent";
import Typography from "@material-ui/core/Typography/Typography";
import CardActions from "@material-ui/core/CardActions/CardActions";
import {bindActionCreators} from "redux";
import * as userActions from "../../actions/registrationActions";
import * as appActions from "../../actions/appActions";
import compose from "recompose/compose";
import withStyles from "@material-ui/core/styles/withStyles";
import style from "../../assets/jss/material-dashboard-react/components/registration";
import connect from "react-redux/es/connect/connect";
import TextField from "@material-ui/core/TextField/TextField";
import CustomBlueButton from "../../components/CustomButtons/CustomBlueButton";
import {signIn, completeNewPassword, signUp} from "../../utils/authUtils";
import {validateEmailFormatOfValue} from "../../components/CustomTextField/validationFunctions";
import ReCAPTCHA from "react-google-recaptcha";

class LoginView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {
        email: "",
        password: "",
        phone: "",
        passwordConfirmation: "",
        securityCode: ""
      },
      registrationMode: false,
      showTotpTextField: false,
      errors: {}
    };
    this.recaptchaRef = React.createRef();
  };

  switchToRegistrationMode = () => {
    this.setState({registrationMode: !this.state.registrationMode, errorMessage: ""});
  }

  handleOkButton = () => {
    if (this.state.registrationMode) {
      this.registerUser();
    } else {
      this.loginUser();
    }
  }

  handleChange = async (event) => {
    let user = Object.assign({}, this.state.user);
    user[event.target.name] = event.target.value;
    await this.setState({user: user});

    if (this.state.registrationMode) {
      if (this.state.user.password !== this.state.user.passwordConfirmation) {
        this.setState({passwordConfirmationErrorMessage: " - Password confirmation does not match!"});
      } else {
        this.setState({passwordConfirmationErrorMessage: ""});
      }
    }
  };

  isFormValid = () => {
    const email = this.state.user.email;
    const password = this.state.user.password;

    if (!email || !password) {
      if (!email && !password) {
        this.setState({emailErrorMessage: " - please enter your email", passwordErrorMessage: " - please enter your password", errorMessage: "Please enter your email and password!"});
        this.setState({passwordErrorMessage: " - please enter your password"});
        return false;
      } else if (!email) {
        this.setState({emailErrorMessage: " - please enter your email", passwordErrorMessage: "", errorMessage: "Please enter your email"});
        return false;
      } else if (!password || password.length < 8) {
        this.setState({emailErrorMessage: "", passwordErrorMessage: " - Password must be at least 8 characters long!", errorMessage: "Password must be at least 8 characters long!"});
        return false;
      } else {
        const emailErrorMessage = validateEmailFormatOfValue(email);
        if (emailErrorMessage) {
          this.setState({emailErrorMessage: emailErrorMessage, errorMessage: emailErrorMessage});
        } else {
          this.setState({emailErrorMessage: "", errorMessage: ""});
        }
        this.setState({passwordErrorMessage: " - please enter your password", errorMessage: "Please enter your password"});
        return false;
      }

    } else if (email) {
      const emailErrorMessage = validateEmailFormatOfValue(email);
      if (emailErrorMessage) {
        this.setState({emailErrorMessage: emailErrorMessage, passwordErrorMessage: "", errorMessage: emailErrorMessage});
        return false;
      }
    }

    if (this.state.registrationMode) {
      if (this.state.user.password !== this.state.user.passwordConfirmation) {
        this.setState({passwordConfirmationErrorMessage: " - Password confirmation does not match!", errorMessage: "Password confirmation does not match!"});
        return false;
      }
    }

    return true;
  };

  // Function that initializes a MutationObserver for the captcha
  initObserver = () => {
    // Find the captcha window by first getting a list of iFrames.
    // After that we find the correct iFrame based on the src attribute
    // The actualy DIV that hides it, is a grandparent. So we get the
    // parentNode prop 2 times.
    const recaptchaWindow = [
      ...document.getElementsByTagName('iframe'),
    ].find((x) => x.src.includes('google.com/recaptcha/api2/bframe'))
      .parentNode.parentNode;

    // Make sure it is defined (it was found in the doc) before we add the observer
    if (recaptchaWindow) {
      recaptchaWindow.style.zoom = 1.3;
      new MutationObserver(() => {
        // ReCaptcha changes these 3 props when going invisible.
        // To solve this, we put an observer on the attributes and
        // check if one of these 3 properties changed from their
        // initial value.
        if (
          recaptchaWindow.style.visibility !== 'visible' ||
          recaptchaWindow.style.opacity !== '1' ||
          recaptchaWindow.style.top !== '10px'
        ) {
          // If changed, put back on default values.
          recaptchaWindow.style.opacity = '1';
          recaptchaWindow.style.visibility = 'visible';
          recaptchaWindow.style.top = '10px';
        }
      }).observe(recaptchaWindow, {
        attributeFilter: ['style'],
      });
    }
  };

  onSubmitWithReCAPTCHA = async () => {
    this.initObserver();
    const token = await this.recaptchaRef.current.executeAsync();
    this.recaptchaRef.current.reset();
    return token;
  }

  loginUser = async () => {
    if (this.isFormValid()) {
      this.props.actions.setLoading(true);
      let token = await this.onSubmitWithReCAPTCHA();
      this.setState({emailErrorMessage: ""});
      this.setState({passwordErrorMessage: ""});
      let customObject = {
        captchaToken: token
      };
      if (this.state.user.securityCode) {
        customObject.securityCode = this.state.user.securityCode;
      }
      signIn(this.state.user.email, this.state.user.password, customObject)
        .then(async (response) => {
          if (response.challengeName === 'NEW_PASSWORD_REQUIRED') {
            await completeNewPassword(response, this.state.user.password);
          }
          await this.props.actions.loginUser(); //get it from the session and put it into store
          this.props.actions.setLoading(false);
          this.props.history.push(this.props.attemptedLocation ? this.props.attemptedLocation : "/home")
        })
        .catch(err => {
          err.message = err.message.replace("PreAuthentication failed with error ", "");
          if (err.message === "Please provide an Authenticator Security Code.") {
            this.setState({showTotpTextField: true, errorMessage: err.message});
          } else {
            this.setState({errorMessage: err.message});
          }
          this.props.actions.setLoading(false);
        });
    }
  };

  registerUser = () => {
    if (this.isFormValid()) {
      signUp(this.state.user.email.toLowerCase(), this.state.user.password)
        .then(() => {
          this.loginUser();
        })
        .catch(error => {
          if (error.code === "InvalidParameterException") {
            this.setState({errorMessage: "Password should be at least 8 characters long!"});
          } else if (error.code === "InvalidPasswordException") {
            this.setState({errorMessage: "Password should be at least 8 characters long!"});
          } else {
            this.setState({errorMessage: error.message});
          }
        })
    }
  }

  render() {
    const {classes} = this.props;
    const {emailErrorMessage, passwordErrorMessage, passwordConfirmationErrorMessage} = this.state;
    return (
      <CardContent>
        <ReCAPTCHA
          style={{ zoom: 1.3 }}
          ref={this.recaptchaRef}
          sitekey="6Lc_B2ogAAAAAOIAT8ceVPyh1swY1xnoPLel4pem"
          size={"invisible"}
        />
        <Typography
          className={classes.errorTextMessage}
        >
          {this.state.errorMessage === "User does not exist." ? "Incorrect username or password." : this.state.errorMessage}
        </Typography>
        <div className="input">
          <TextField
            fullWidth
            id="login_email_field"
            label={"Email"}
            error={!!emailErrorMessage}
            margin="dense"
            type="email"
            value={this.state.user.email}
            onChange={this.handleChange}
            name="email"
            required={this.state.registrationMode}
          />
          <TextField
            fullWidth
            id="login_password_field"
            label={"Password"}
            error={!!passwordErrorMessage}
            margin="dense"
            type="password"
            value={this.state.user.password}
            onChange={this.handleChange}
            name="password"
            required={this.state.registrationMode}
          />
          {
            this.state.registrationMode
              ? <div>
                <TextField
                  fullWidth
                  id="login_password_field"
                  label={"Password confirmation"}
                  error={!!passwordConfirmationErrorMessage}
                  margin="dense"
                  type="password"
                  value={this.state.user.passwordConfirm}
                  onChange={this.handleChange}
                  name="passwordConfirmation"
                  required={this.state.registrationMode}
                />
                <br/>
                <Typography align={"left"} variant={"body1"}>- By signing up, I agree to have the submitted data stored, but not given away to any other third parties</Typography>
                <Typography align={"left"} variant={"body1"}>- Problems signing up? Write us on WhatsApp, and we will help sort it out! <b>+40 743 211 234</b></Typography>
              </div>
              : null
          }
          {
            !this.state.registrationMode && this.state.showTotpTextField
              ?  <TextField
                fullWidth
                id="login_totp_field"
                label={"Security Code"}
                margin="dense"
                value={this.state.user.securityCode}
                onChange={this.handleChange}
                name="securityCode"
                required={this.state.registrationMode}
              />
              : null
          }
        </div>
        <CardActions className={classes.cardActions}>
          <CustomBlueButton
            style={{top: this.state.registrationMode ? 0 : "1px", marginRight: this.state.registrationMode ? "20px" : 0}}
            color="primary"
            onClick={this.handleOkButton}
            label={this.state.registrationMode ? "Sign up" : "Login"}
            handleEnterKeypress
            id={"login_button"}
            variant={this.state.registrationMode ? "contained" : ""}
          />
          <span>or</span>
          <CustomBlueButton
            style={{top: this.state.registrationMode ? "1px" : 0, marginLeft: this.state.registrationMode ? "10px" : "20px"}}
            variant={this.state.registrationMode ? "" : "contained"}
            color="primary"
            onClick={this.switchToRegistrationMode}
            label={this.state.registrationMode ? "Go to login" : "Register for free"}
          />
        </CardActions>
      </CardContent>
    )
  }
}

function mapStateToProps(store, ownProps) {
  return {
    attemptedLocation: store.user.attemptedLocation,
    discountCodeData: store.mainReducer.discountCodeData,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign({}, userActions, appActions), dispatch)
  }
}

export default compose(
  withStyles(style),
  connect(mapStateToProps, mapDispatchToProps)
)(LoginView);
