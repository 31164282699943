import React from "react";
import PropTypes from "prop-types";
import {Redirect, Route, Switch} from "react-router-dom";
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import "../../assets/css/webkit-scrollbar.css";
import withStyles from "@material-ui/core/styles/withStyles";
import Header from "../../components/Header/Header.jsx";
import Sidebar from "../../components/Sidebar/Sidebar.jsx";
import dashboardStyle from "../../assets/jss/material-dashboard-react/layouts/dashboardStyle.jsx";
import {connect} from "react-redux";
import compose from "recompose/compose";
import getSidemenuRoutes from "../../routes/sidemenuRoutes.jsx";
import CustomCircularProgress from "../../components/CustomCircularProgress";
import ConfirmationDialog from "../../components/ConfirmationDialog";
import {bindActionCreators} from "redux";
import * as appActions from "../../actions/appActions";
import {drawerWidth, mobileOpenWidth} from "../../assets/jss/material-dashboard-react";
import {isUserAuthenticated} from "../../utils/authUtils";

const switchRoutes = () => {
  return (
    <Switch>
      {getSidemenuRoutes().map((prop, key) => {
        if (prop.redirect)
          return <Redirect from={prop.path} to={prop.to} key={key}/>;
        if (prop.childrenRoutes)
          return prop.childrenRoutes.map(route => <Route path={route.path} component={prop.component} key={key}/>);
        else
          return <Route path={prop.path} component={prop.component} key={key}/>;
      })}
    </Switch>
  )
};

class App extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      mobileOpen: window.innerWidth < mobileOpenWidth,
      mobileSidebarOpen: false,
      modalAddNewItemType: "",
      currentUser: {
        firstName: "",
        lastName: "",
        profilePicture: {},
        rolePermissions: {role: {}, permissions: []}
      }
    };
  }

  handleDrawerToggle = () => {
    this.setState({mobileSidebarOpen: !this.state.mobileSidebarOpen});
  };

  resizeFunction = () => {
    if (window.innerWidth >= mobileOpenWidth) {
      this.setState({mobileOpen: false});
    } else {
      this.setState({mobileOpen: true});
    }
  };

  componentDidMount = async () => {
    if (navigator.platform.indexOf("Win") > -1) {
      new PerfectScrollbar(this.refs.mainPanel);
    }
    window.addEventListener("resize", this.resizeFunction);

    isUserAuthenticated().then(async (isAuthenticated) => {
      if (isAuthenticated) {
        //await this.props.actions.loadCurrentUser();
      }
    });
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.history.location.pathname !== prevProps.location.pathname) {
      this.refs.mainPanel.scrollTop = 0;
      if (this.state.mobileOpen) {
        this.setState({mobileOpen: false});
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      currentUser: {
        firstName: nextProps.currentEmployee ? (nextProps.currentEmployee.firstName ? nextProps.currentEmployee.firstName : "") : "",
        lastName: nextProps.currentEmployee ? (nextProps.currentEmployee.lastName ? nextProps.currentEmployee.lastName : "") : "",
        profilePicture: nextProps.currentEmployee
          ? (nextProps.currentEmployee.imageRequest
              ? nextProps.currentEmployee.imageRequest.data
                ? nextProps.currentEmployee.imageRequest.data
                : nextProps.currentEmployee.imageRequest.url
                    ? nextProps.currentEmployee.imageRequest.url
                    : nextProps.currentEmployee.imageRequest.data === ""
                      ? {}
                      : nextProps.currentEmployee.imagePath
              : (nextProps.currentEmployee.imagePath
                  ? nextProps.currentEmployee.imagePath
                  : {}
              )
          )
          : {},
        rolePermissions: nextProps.currentEmployee ? (nextProps.currentEmployee.rolePermissions ? nextProps.currentEmployee.rolePermissions : {}) : {}
      },
      mobileOpen: window.innerWidth < mobileOpenWidth
    });
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeFunction);
  }

  handleCloseInfoDialog = () => {
    this.props.actions.setMessage();
  };

  getWelcomeText = () => {
    let welcomeText = "Hello ";

    if (this.props.user && this.props.user.user) {
      welcomeText += this.props.user.user;
    } else {
      welcomeText += "user";
    }
    return welcomeText;
  };

  render() {
    const {classes, loading, message, currentEmployee, ...rest} = this.props;
    const {mobileOpen, mobileSidebarOpen, currentUser} = this.state;

    return (
      <div className={classes.wrapper}>
        <Header
          handleDrawerToggle={this.handleDrawerToggle}
          mobileOpen={this.state.mobileOpen}
          handleAddNewItemModal={this.handleAddNewItemModal}
          {...rest}
        />
        <Sidebar
          currentUser={currentUser}
          routes={getSidemenuRoutes()}
          welcomeText={this.getWelcomeText()}
          userPicture={this.state.currentUser.profilePicture}
          handleDrawerToggle={this.handleDrawerToggle}
          mobileOpen={mobileSidebarOpen}
          color="defaultColor"
          {...rest}
        />

        <CustomCircularProgress show={loading} mobileOpen={mobileOpen}/>
        <div className={classes.mainPanel}
             ref="mainPanel"
             style={!mobileOpen && this.props.drawerWidth < drawerWidth
               ? {width: `calc(100% - ${this.props.drawerWidth}px)`}
               : null}
        >
          <ConfirmationDialog
            content={message.content}
            open={message.show}
            textYes={"Ok"}
            type={message.type}
            handleCloseModal={this.handleCloseInfoDialog}
            actionYes={this.handleCloseInfoDialog}
            actionNo={this.handleCloseInfoDialog}
          />
          <div className={classes.content}>
            <div className={classes.container}>{switchRoutes()}</div>
          </div>
        </div>
      </div>
    );
  }
}

App.propTypes = {
  classes: PropTypes.object.isRequired
};

function mapStateToProps(state, ownProps) {
  return {
    currentEmployee: {},
    drawerWidth: state.app.drawerWidth,
    user: state.user,
    loading: state.app.isLoading,
    message: state.app.message
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign({}, appActions), dispatch)
  }
}

export default compose(
  withStyles(dashboardStyle),
  connect(mapStateToProps, mapDispatchToProps)
)(App);
