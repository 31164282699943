
import {
  boxShadow,
  drawerWidth,
  drawerWidthMin,
  defaultFont,
  fontColorPrimary,
  grayColor,
  blackColor,
  primaryColor,
  whiteColor,
  secondaryBackgroundColor,
  primaryBoxShadow,
  transition
} from "../../../../assets/jss/material-dashboard-react.jsx";

const sidebarStyle = theme => ({
  drawerPaper: {
    border: "none",
    position: "fixed",
    top: "0",
    bottom: "0",
    left: "0",
    zIndex: "1001",
    ...boxShadow,
    width: drawerWidth,
    [theme.breakpoints.up("md")]: {
      position: "fixed",
      height: "100%"
    },
    [theme.breakpoints.down("sm")]: {
      width: drawerWidth,
      ...boxShadow,
      position: "fixed",
      display: "block",
      top: "0",
      height: "100vh",
      right: "auto",
      left: "0",
      zIndex: "1032",
      visibility: "visible",
      overflowY: "visible",
      borderTop: "none",
      textAlign: "left",
      paddingRight: "0px",
      paddingLeft: "0",
      transform: `translate3d(${drawerWidth}px, 0, 0)`,
      ...transition
    }
  },
  drawerPaperClosed: {
    border: "none",
    position: "fixed",
    top: "0",
    bottom: "0",
    left: "0",
    zIndex: "1001",
    ...boxShadow,
    width: drawerWidthMin,
    [theme.breakpoints.up("md")]: {
      position: "fixed",
      height: "100%"
    },
    [theme.breakpoints.down("sm")]: {
      width: drawerWidthMin,
      ...boxShadow,
      position: "fixed",
      display: "block",
      top: "0",
      height: "100vh",
      right: "auto",
      left: "0",
      zIndex: "1032",
      visibility: "visible",
      overflowY: "visible",
      borderTop: "none",
      textAlign: "left",
      paddingRight: "0px",
      paddingLeft: "0",
      transform: `translate3d(${drawerWidthMin}px, 0, 0)`,
      ...transition
    }
  },
  buttonStyle: {
    padding: 0,
    "&:focus": {
      background: `${primaryColor}`
    },
    "&:hover": {
      background: `${primaryColor}`
    }
  },
  logo: {
    position: "relative",
    padding: "15px 15px",
    zIndex: "4",
    background: primaryColor,
    display: "flex",
    height: "45px",
    color: whiteColor,
    "&:after": {
      content: '""',
      position: "absolute",
      bottom: "0"
    }
  },
  logoLink: {
    ...defaultFont,
    textTransform: "uppercase",
    padding: "5px 0",
    display: "block",
    fontSize: "18px",
    textAlign: "left",
    fontWeight: "400",
    lineHeight: "30px",
    textDecoration: "none",
    backgroundColor: "transparent",
    "&,&:hover": {
      color: whiteColor
    }
  },
  logoImage: {
    color: "#FFF",
  },
  menuItems: {
    width: "250px",
    marginTop: "60px"
  },
  textMenuItems: {
    verticalAlign: "super"
  },
  dividerFooter: {
    width: "90%",
    margin: "auto",
    position: "absolute",
    bottom: "60px",
    left: "4%",
    border: `1px solid ${secondaryBackgroundColor}`
  },
  minimizeIcon: {
    color: theme.palette.action.active,
    position: "absolute",
    bottom: "-48px",
    right: "0px",
    cursor: "pointer",
    borderRadius: "15%",
    fontSize: "35px",
    "&:hover": {
      background: theme.palette.action.hover
    }
  },
  maximizeIcon: {
    color: theme.palette.action.active,
    position: "absolute",
    bottom: "15px",
    left: "25px",
    cursor: "pointer",
    borderRadius: "15%",
    fontSize: "35px",
    "&:hover": {
      background: theme.palette.action.hover,
    }
  },
  img: {
    width: "35px",
    top: "10px",
    position: "absolute",
    verticalAlign: "middle",
    border: "0",
    borderRadius: "999px",
  },
  background: {
    position: "absolute",
    zIndex: "1",
    height: "100%",
    width: "100%",
    display: "block",
    top: "0",
    left: "0",
    backgroundSize: "cover",
    backgroundPosition: "center center",
    "&:after": {
      position: "absolute",
      zIndex: "3",
      width: "100%",
      height: "100%",
      content: '""',
      display: "block",
      background: blackColor,
      opacity: ".8"
    }
  },
  list: {
    marginTop: "20px",
    paddingLeft: "0",
    paddingTop: "0",
    paddingBottom: "0",
    marginBottom: "0",
    listStyle: "none",
    position: "unset"
  },
  item: {
    position: "relative",
    display: "block",
    textDecoration: "none",
    "&:hover,&:focus,&:visited,&": {
      color: grayColor
    }
  },
  itemLink: {
    width: "auto",
    margin: "10px 15px 0",
    borderRadius: "3px",
    position: "relative",
    display: "block",
    padding: "10px 15px",
    backgroundColor: "transparent",
    ...defaultFont
  },
  itemIcon: {
    width: "24px",
    height: "30px",
    fontSize: "24px",
    lineHeight: "30px",
    float: "left",
    marginRight: "15px",
    textAlign: "center",
    verticalAlign: "middle",
    color: fontColorPrimary
  },
  itemIconClosed: {
    width: "24px",
    height: "30px",
    color: fontColorPrimary,
    verticalAlign: "middle",
    marginLeft: "2px"
  },
  itemText: {
    ...defaultFont,
    margin: "0",
    lineHeight: "25px",
    fontSize: "16px",
    fontWeight: 400,
    color: fontColorPrimary
  },
  fontColor: {
    color: whiteColor
  },
  purple: {
    backgroundColor: primaryColor,
    ...primaryBoxShadow,
    "&:hover": {
      backgroundColor: primaryColor,
      ...primaryBoxShadow
    }
  },
  defaultColor: {
    backgroundColor: `${primaryColor} !important`,
    "&:hover": {
      backgroundColor: primaryColor
    }
  },
  miniSidebarWrapper: {
    position: "relative",
    height: "calc(100vh - 75px)",
    overflow: "auto",
    width: "260px",
    zIndex: "4",
    overflowScrolling: "touch"
  },
  activePro: {
    [theme.breakpoints.up("md")]: {
      position: "absolute",
      width: "100%",
      bottom: "13px"
    }
  }
});

export default sidebarStyle;
