import Home from "@material-ui/icons/Home";
import HomeView from "../views/HomeView";

const getSidemenuRoutes = () => {
  return [
    {
      path: "/home",
      sidebarName: "Home",
      icon: Home,
      component: HomeView
    },
    {
      redirect: true,
      path: "/",
      to: "/home",
      navbarName: "Redirect"
    }
  ];
};

export default getSidemenuRoutes;
