export const USER_CREATE = "USER_CREATE";
export const USER_LOGIN = "USER_LOGIN";
export const USER_GET_SUCCESS = "USER_GET_SUCCESS";
export const USER_CLEAR = "USER_CLEAR";
export const USER_GET_FAIL ="USER_GET_FAIL";
export const USER_CLEAR_REDUCER = "USER_CLEAR_REDUCER";
export const USER_TOKEN_VALIDATED = "USER_TOKEN_VALIDATED";
export const USER_SET_INVITATION_TOKEN = "USER_SET_INVITATION_TOKEN";
export const REGISTRATION_SET_ATTEMPTED_LOCATION = "REGISTRATION_SET_ATTEMPTED_LOCATION";
export const CHANGE_HEADER_BUTTONS = "CHANGE_HEADER_BUTTONS";
export const CHANGE_PAGE_TITLE = "CHANGE_PAGE_TITLE";
export const SET_FLOATING_BUTTON = "SET_FLOATING_BUTTON";
export const APP_SET_LOADING = "APP_SET_LOADING";
export const APP_SET_MESSAGE = "APP_SET_MESSAGE";
export const APP_SWITCH_DRAWER_WIDTH = "APP_SWITCH_DRAWER_WIDTH";
export const APP_PROJECT_CHANGE_VIEW = "PROJECT_CHANGE_VIEW";
export const APP_TOOL_CHANGE_VIEW = "TOOL_CHANGE_VIEW";
export const APP_EMPLOYEE_CHANGE_VIEW = "EMPLOYEE_CHANGE_VIEW";
export const APP_ADD_EMPLOYEE_TO_CART_MODE = "APP_ADD_EMPLOYEE_TO_CART_MODE";
export const APP_ADD_TOOL_TO_CART_MODE = "APP_ADD_TOOL_TO_CART_MODE";
export const APP_ASSIGNED_EMPLOYEES_FROM_PROJECT_MODE = "APP_ASSIGNED_EMPLOYEES_FROM_PROJECT_MODE";
export const APP_ASSIGNED_TOOLS_FROM_PROJECT_MODE = "APP_ASSIGNED_TOOLS_FROM_PROJECT_MODE";
export const ORDERS_GET_SUCCESS = "ORDERS_GET_SUCCESS";
