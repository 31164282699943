import { combineReducers } from "redux";
import user from "./registrationReducer";
import headerButtons from "./headerButtonsReducer";
import app from "./appReducer";
import mainReducer from "./mainReducer";
import registrationReducer from "./registrationReducer";

const rootReducer = combineReducers({
  user,
  mainReducer,
  registrationReducer,
  headerButtons,
  app
});

export default rootReducer;
