import {
  container,
  primaryColor,
  drawerWidth
} from "../../../../assets/jss/material-dashboard-react.jsx";
import {headerHeight} from "../../../../assets/jss/material-dashboard-react";

const headerStyle = () => ({
  appBar: {
    backgroundImage: `linear-gradient(to right, ${primaryColor}, #697daf)`,
    boxShadow: "none",
    borderBottom: "0",
    marginBottom: "0",
    width: `calc(100% - ${drawerWidth}px)`,
    paddingTop: "10px",
    zIndex: "1000",
    color: "#555555",
    border: "0",
    padding: "10px 0",
    minHeight: `${headerHeight}px`,
    display: "block"
  },
  container: {
    ...container,
    minHeight: "55px"
  },
  center: {
    width: "50%"
  },
  cartButton: {
    background: primaryColor,
    borderRadius: "50%",
    boxShadow: "0px 5px 15px grey",
    color: "#FFF",
    fontSize: "32px",
    fontWeight: "100px",
    width: "60px",
    height: "60px",
    position: "fixed",
    top: "44px",
    zIndex: "1000",
    right: "106px",
    cursor: "pointer"
  },
  minCartButton: {
    width: "40px",
    height: "40px",
    top: "54px",
    right: "66px"
  },
  cartIcon: {
    color: "#FFF",
    fontSize: "36px",
    marginTop: "12px",
    marginLeft: "13px"
  },
  minCartIcon: {
    fontSize: "30px",
    marginLeft: "6px",
    marginTop: "6px"
  },
  title: {
    color: "white", 
    fontSize: "calc(10px + 1vw)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    wordBreak: "break-word",
    fontWeight: 500
  },
  appResponsive: {
    top: "8px"
  },
  iconStyle: {
    color: "#FFF",
    fontSize: "36px !important",
    marginLeft: "20px",
    "&:hover": {
      cursor: "pointer"
    }
  }
});

export default headerStyle;
